.container {
  display: flex;
  flex-direction: row;
  height: 100vh;
  flex-wrap: wrap;
  padding: 1rem;
  flex-wrap: wrap;
  /* background-color: #fff; */
}

.segment {
  margin: 1rem;
  flex: 1;
  /* background-color: rgb(238, 238, 238); */
  background-color: #fff;
  height: 100vh;
  border-radius: 2rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  /* justify-content: space-between; */
  /* clip-path: polygon(0 6%, 100% 0, 100% 94%, 0% 100%); */
}
.empDrawer {
  background-color: #1d2d50;
  font-family: "DM Mono", monospace;
  padding: 2rem;
  flex: 3;
  box-shadow: 10px 10px 5px -4px rgba(0, 0, 0, 0.45);
  height: 100%;
}
.empCustDrawer {
  background-color: #2c002e;
  font-family: "DM Mono", monospace;
  padding: 2rem;
  flex: 3;
  box-shadow: 10px 10px 5px -4px rgba(0, 0, 0, 0.45);
  height: 400%;
}
.empSerDrawer {
  background-color: #03141f;
  font-family: "DM Mono", monospace;
  padding: 2rem;
  flex: 3;
  box-shadow: 10px 10px 5px -4px rgba(0, 0, 0, 0.45);
  height: 400%;
}
.segment::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.segment::-webkit-scrollbar-thumb {
  background: #201c29;
  border-radius: 10px;

  box-shadow: inset 2px 2px 2px hsla(0, 0%, 100%, 0.25),
    inset -2px -2px 2px rgba(0, 0, 0, 0.25);
}

.segment::-webkit-scrollbar-track {
  background: linear-gradient(90deg, #201c29, #201c29 1px, #17141d 0, #17141d);
}
.empCard {
  display: flex;
  background-color: #1d2d50;
  flex-direction: column;
}
.textS {
  color: #fff;
  font-size: 1.5rem;
  align-self: center;
}
.textSm {
  color: #fff;
  font-size: 1.5rem;
  align-self: center;
}
.ButtonEmp {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  /* clip-path: polygon(1% 0, 100% 0, 100% 100%, 0 88%); */
  flex-wrap: wrap;

  /* margin: 1rem; */
  padding: 1vw;
  position: relative;
  cursor: pointer;
  transition: 200ms;
}

.ButtonEmp:hover {
  transform: scale(0.9);
}
.ButtonEmp:active {
  transform: scale(1);
}
.GridItemTop {
  padding: 1vw;
  
}
.Icon {
  position: absolute;
  font-size: 13rem;
  opacity: 0.3;
  overflow: hidden;
  top: -2rem;
  right: -4rem;
}
.Heading {
  font-size: 3rem;
  font-family: inherit;
  font-weight: 200;
  font: outline;
}
.HeadingDiv {
  background-color: #fe5236;
  color: #fff;
  margin: 1vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 2px;
}
.AddEmp {
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  margin-top: 2vh;
  
}
.inputDiv {
  margin: 5px;
}
.AddHeading {
  background-color: #fe5236;
  color: #fff;
  font-size: 1.4rem;
  font-weight: 200;
  display: flex;
  text-align: right;
  align-items: center;
  justify-content: flex-end;
  padding: 3px;
}
.NotiHeading {
  background-color: #1d2d50;
  color: #fff;
  font-size: 1.4rem;
  font-weight: 200;
  display: flex;
  text-align: right;
  align-items: center;
  justify-content: flex-end;
  padding: 3px;
}
.chartHeading {
  background-color: #17141d;
  color: #fff;
  font-size: 1.4rem;
  font-weight: 200;
  display: flex;
  text-align: right;
  align-items: center;
  justify-content: flex-end;
  padding: 3px;
  position: relative;
  overflow: hidden;
}
.insideDiv {
  display: flex;
  flex-wrap: wrap;
  
  margin-top: 0.5vh;
}
.inputIn {
  padding: 5px;
}
.boxTwo {
  display: flex;
  flex-direction: column;
  background-color: #fff;
}
.notiHead {
  background-color: #1d2d50;
  color: #fff;
  font-size: 1.3rem;
  font-weight: 100;
}

.notiBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
}
.line {
  display: flex;
  justify-content: space-between;
  background-color: aliceblue;
  align-items: center;
  margin: 0.5rem;
  padding: 0.1rem;
}
.lineText {
  color: #1d2d50;
  font-size: 1.2rem;
  font-weight: 400;
}
.lineTextNum {
  color: #1d2d50;
  font-size: 2rem;
  font-weight: 400;
}
.iconHead {
  position: absolute;
  font-size: 5rem;
  opacity: 0.3;
  overflow: hidden;
  top: -2rem;
  left: 0rem;
}
.today {
  padding: 0.2rem;
}
.chartBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  margin: 0.2rem;
 
}
