@import url("https://fonts.googleapis.com/css2?family=DM+Mono:wght@300;400;500&display=swap");
.cin {
  padding: 20px;
  /* border-color: #ed8337; */
  border-width: 20px;
}
.sub {
  color: "red";
  font-family: "DM Mono";
  font-size: 1.4rem;
  display: flex;
  justify-content: "space-between";
  padding: 2px;
}
.head {
  background-color: #ed8337;
  padding: 10px;
  color: aliceblue;
}
.card {
  color: #000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 16px;
  border-top-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background: #fff;
  box-shadow: 0 -1rem 3rem rgb(49, 20, 51);
  font-family: "DM Mono", monospace;

  transition: 0.2s;
  flex-direction: column;
  height: 10rem;
  position: relative;
  width: 20rem;
  min-width: 50px;
  padding: 0px;
}
.card:hover {
  transform: translateY(-1rem);
}
.card:hover ~ .card {
  transform: translateY(7rem);
}
.card:not(:first-child) {
  margin-top: -7rem;
}
