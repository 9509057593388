.image {
  position: absolute;
  background-image: url(./TempBill.svg);
  filter: drop-shadow(0px 15px 25px rgba(0, 0, 0, 0.3));
  top: 4%;
  margin-top: 20px;
  right: 6%;
  width:  90%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  overflow: scroll;
}
h2 {
  position: absolute;
  font-size: 20px;
  top: 60px;
  right: 60px;
  font-family: Montserrat;
  font-weight: 700;
  text-align: center;
  color: black;
  width: inherit; 
}
h1{
    font-family: Montserrat;
}
.image-contain{
  display:  flex;
  flex-direction: row;  
  justify-content: space-around;
  /* margin-top: 100px; */
  margin-left:50px ;
  

}
.text-contain{
  font-size: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  
}