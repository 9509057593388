.reportsColor {
  background: #f0563c;
}
.reportsLabel {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 2.5vw;
  text-align: right;
  width: 100%;
  margin-right: 4%;
  color: white;
}
.salesBG {
  object-fit: cover;
  background: url("./sales.png");
  cursor: pointer;
}
.profitBG {
  object-fit: cover;
  background: url("./profit.jpg");
  cursor: pointer;
}
.customerBG {
  object-fit: cover;
  background: url("./customerReports.png");
  cursor: pointer;
}
.packageBG {
  object-fit: cover;
  background: url("./packageReports.png");
  cursor: pointer;
}
.serviceBG {
  object-fit: cover;
  background: url("./serviceReports.png");
  cursor: pointer;
}
.employeeBG {
  object-fit: cover;
  background: url("./employeeReports.png");
  cursor: pointer;
}
.inventoryBG {
  object-fit: cover;
  background: url("./inventoryReports.png");
  cursor: pointer;
}
.overlayCards {
  background-color: rgba(35, 32, 59, 0.55);
  transition: 0.15s;
}
.overlayCards:hover {
  background-color: rgba(0, 0, 0, 0.8);
}
.dsrLabel {
    cursor: pointer;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  padding: 2.5vw;
  font-size: 1.75vw;
  color: #ffffff;
  padding-top: 5vh;
  transition: 0.5s;
  height: 100%
}
.dsrLabel:hover{
    padding-top: 2vh;
    font-size: 2.25vw;

}
.invLabel {
  cursor: pointer;
font-family: Montserrat;
font-style: normal;
font-weight: 300;
padding: 2.5vw;
font-size: 1.25vw;
color: #ffffff;
padding-top: 2.5vh;
transition: 0.3s;
height: 100%
}
.invLabel:hover{
  padding-top: 2vh;
  font-size: 1.75vw;

}
.crLabel {
    cursor: pointer;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  padding: 2.5vw;
  font-size: 1.75vw;
  color: #ffffff;
  padding-top: 6.5vh;
  margin-bottom: -5vh;
  text-align: left;
  transition: 0.4s;;
}
.crLabel:hover {

    font-size: 2.25vw;;
    padding-left: 3vw;
    padding-top: 3.5vh;
}
.halkagrey{
  background-color: rgba(0, 0, 0, 0.8);

}
.rotateForward{
  transform: rotate(180deg);
}
.apnaClose{
  transform: scale(1);
  transition: 0.2s;
  filter: grayscale(1);
}
.apnaClose:hover{
  transform: scale(1.15)rotate(90deg);;
  filter: grayscale(0);
}
.apnaBack{
  transition: 0.2s;
  filter: grayscale(1);
}
.apnaBack:hover{
  filter: grayscale(0);
}
.apnaForward{
  transition: 0.2s;
  filter: grayscale(1);
}
.apnaForward:hover{
  filter: grayscale(0);
}

