.donutchart-track {
  fill: transparent;
  stroke: #dadada;
  stroke-width: 26;
}
.donutchart-indicator {
  fill: transparent;
  stroke: #f0563c;
  stroke-width: 26;
  stroke-dasharray: 0 10000;
  transition: stroke-dasharray 0.3s ease;
}

.donutchart {
  margin-left: 0px;
  border-radius: 50%;
  display: block;

}

.donutchart-text {
  font-family: "Montserrat";
  fill: #f0563c;
  font-size: 20;
}
.donutchart-text-val {
  font-size: 4vw;
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
}
.donutchart-text-percent {
  font-size: 14px;
}
.donutchart-text-label {
  font-size: 1.25vw;
  fill: #000000;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}

/* .bodybody {
  padding: 40px;
  font-family: "Montserrat";
  text-align: center;
}

.inputinput {
  padding: 8px 10px;
  border-radius: 4px;
  border: 1px solid #ddd;
  width: 154px;
} */
