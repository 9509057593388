.employee-show-main-body{
    display: block;
    width: 100%;
    height: 7.5%;
}
.titleEmp{

    font-family: Montserrat;
    font-size: 20px;
    font-weight: 600;
    color: black
}