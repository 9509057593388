.container {
  display: flex;
  flex-direction: column;
  margin: 0.5rem;
  background-color: #fcfdff;

  -webkit-box-shadow: 10px 10px 29px -12px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 29px -12px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 29px -12px rgba(0, 0, 0, 0.75);
}
.header {
  background-color: blueviolet;
  color: aliceblue;
  display: flex;
  justify-content: flex-end;
  padding: 0.3rem;
}
.headtxt {
  font-size: 1.3rem;
  font-weight: 200;
}
.box {
  padding: 1rem;
}
.det {
  display: flex;
  justify-content: space-between;
}
