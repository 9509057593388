.cashAnimation {
  filter: drop-shadow(0px 0px 0px #ffffff);
  transition: 0.2s;
  margin-top: 20px;
  width: 200px;
  cursor: pointer;
  margin-left: 0px;
  margin-right: 40px;
}
.cashAnimation:hover {
  filter: drop-shadow(0px 5px 20px #c1c1c1);
  transition: 0.2s;
}

.onlineAnimation {
  filter: drop-shadow(0px 0px 0px #ffffff);
  transition: 0.2s;
  margin-top: 20px;
  width: 200px;
  cursor: pointer;
  margin-left: 0px;
  margin-left: 40px;
}
.onlineAnimation:hover {
  filter: drop-shadow(0px 5px 20px #c1c1c1);
  transition: 0.2s;
}
.buttonColorModal {
  background: #f0563c;
  background-color: #f0563c;
  color: #f0563c;
}

.container-div-main-hello {
  width: 95.5%;
  height: 100vh;
  background-color: #eeeeee;
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  margin-left: 2vw;
  margin-top: 5vw;
  border-top-left-radius: 3.5vw;
  border-top-right-radius: 3.5vw;
  padding-left: 2vw;
}
.invoice {
  width: 10vw;
}
.invoice-image {
  width: 40vw;
}
.row-1 {
  background-color: #eeeeee;
  flex: 1;
  height: 90%;
  margin: 3vw 1vw 1vw 3vw;
  display: flex;
  flex-direction: column;
}
.row-2-service {
  display: flex;
  flex: 1;
  height: 100%;
  background-color: white;
  margin: 0vw 0vw 1vw 0vw;
  flex-direction: column;
}
.Contain-left-div {
  display: flex;
  flex-direction: column;
  margin-top: -3vh;
}
.contain-span {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  font-size: 1.5vw;
}
.cash-button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 5vw;
  margin-right: 10vw;
  transform: scale(1.5);
}

h2 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 60px;
  line-height: 73px;
  letter-spacing: 0.29em;
}

.row-1-subdiv {
  display: flex;
  flex-direction: row;
  margin-top: 3rem;
  background-color: rosybrown;
}
.h4-heading {
  display: flex;
  width: 40vw;
  align-items: center;
  justify-content: space-between;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 1.5vw;
  margin-top: 4vh;
}
.service-div {
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: #d2d2d2;
  align-items: center;
  justify-content: center;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 2vw
}
.amount-div {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  background-color: #eeeeee;
  justify-content: center;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 2vw;
  border-top-right-radius: 3.5vw;
}
.row-2-headerDiv-service {
  display: flex;
  flex-direction: row;
  height: 10%;
}
.row-2Contain {
  display: flex;
  flex-direction: column;
  height: 40vh;
  background-color: white;
  overflow: scroll;
  /* background-image: linear-gradient(to bottom right, white, #eeeeee); */
}
.bill-tabs-service {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 95%;
  height: 100%;
  background-color: #eeeeee;
  /* background-image: linear-gradient(to bottom right, #eeeeee, white); */
  border-radius: 10px;
  margin-top: 0.75vw;
  margin-left: 1vw;
  padding: 1vw;
}
h5 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 1.5vw;
  margin-top: 2vh;
  text-align: left;
  padding-left: 3vw;
  padding-right: 3vw;
}
.abcd {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 60px;
  line-height: 73px;
  letter-spacing: 0.29em;
}
.Total-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  margin-left: 2vw;
}
.Total-div-amt-heading {
  display: flex;
  margin-left: 1vw;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  font-size: 2vw;
  text-align: left;
  padding-left: 2vw;
  padding-right: 2vw;
}
.Total-div-amt-val {
  display: flex;
  margin-left: 1vw;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  font-size: 2vw;
  text-align: left;
  padding-left: 2vw;
  padding-right: 2vw;
}

.invoiceLogo {
  background-color: tomato;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.hr-line {
  background-color: tomato;
  border: 1px solid #fe5236;
  border-radius: 100px;
  width: 42vw;
  margin-left: 2vw;
}
.gst-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  margin-left: 2vw;
}
.gst-heading-div {
  display: flex;
  margin-left: 1vw;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  font-size: 2vw;
  text-align: left;
  padding-left: 2vw;
  padding-right: 2vw;
}
.gst-value-div {
  display: flex;
  margin-left: 1vw;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  font-size: 2vw;
  text-align: left;
  padding-left: 2vw;
  padding-right: 2vw;
}

.net-amt-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  margin-left: 2vw;
}
.net-heading-div {
  display: flex;
  margin-left: 1vw;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  font-size: 2vw;
  text-align: left;
  padding-left: 2vw;
  padding-right: 2vw;
}
.net-value-div {
  display: flex;
  margin-left: 1vw;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  font-size: 2vw;
  text-align: left;
  padding-left: 2vw;
  padding-right: 2vw;
}
.amount-div-with-delete {
  /* background-color: #f0563c; */
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.amount-div-with-delete-button {
  color: red;
  font-size: 3vw;
  cursor: pointer;
  margin-right: 10px;
}
@media only screen and (max-width: 600px) {
  .container-div-main-hello {    
    /* background-color:red; */
    display: flex;
    flex-direction: column;  
  }
 
  .row-1 {
    /* background-color:palegreen; */
    flex: 1;
    height: 40vh;   
  }
  .Contain-left-div{
    /* background-color: paleturquoise; */
    display: flex;
    flex: 1;
    height: 37vh;
    
  }
  .h4-heading {
    /* background-color: yellowgreen; */
    display: flex;
    width: 100%;
    height: 10vh;
    align-items: center;
    justify-content: space-between;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 3vw;
    margin-top: 0.5vh;
  }
  .contain-span {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    font-size: 3vw;
  }
  .cash-button {
        
    display: flex;
    justify-content: center;
    margin-top: 1vh;
    margin-left: 55vw;
    height: 5vh;
    width: 20vw;    
    transform: scale(1.5);
  }
  .row-2-service {    
    height: 30vh;
    width: 92vw;
    margin-top: 1vh;
    margin-right: 1vw;
    /* margin-left: -1vh; */
    /* background-color: yellow;     */
  }
  .row-2-headerDiv-service {
    background-color: tomato;
    /* margin-top: 0.5vh;     */
    height: 4vh;
  }
  .service-div {  
      font-size: 4vw
  }
  .amount-div {
  
    font-size: 4vw;
    border-top-right-radius: 0px;
  }
  .row-2Contain {
    display: flex;
    position: static;
    flex-direction: column;
    height: 90vh;    
    background-color: white;
    overflow: scroll;
    /* background-color: seagreen; */
    
  }
  .bill-tabs-service {  
    border-radius: 5px;    
    padding: 1vw;    
  }
  h5 {   
    font-size: 3vw;    
  }
  .hr-line {  
    width: 80vw;   
  }
  .Total-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    margin-left: 2vw;
    /* background-color: yellowgreen; */
  }
  .Total-div-amt-heading {
   
    font-size: 3vw;
   
    /* background-color: red; */
  }
  .Total-div-amt-val {
    /* background-color: royalblue; */
    
    font-size: 3vw;
    
  }
  .gst-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    margin-left: 2vw;
    /* background-color: royalblue; */
  }
  .gst-heading-div {
   
    font-size: 3vw;
   
  }
  .gst-value-div {
    
    font-size: 3vw;
   
  }
  .net-amt-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    margin-left: 2vw;
    /* background-color: seagreen; */
  }
  .net-heading-div {
   
    font-size: 4vw;
   
    
  }
  .net-value-div {
   
    font-size: 4vw;
    
  }
}