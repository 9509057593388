.card-container-admin {
    background-color: white;
    position: relative;
    width: 15vw;
    height: 15vw;
    margin: 2vw ;
    border-radius: 1vw;
  }
  
  .mini-card-top {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 8vw;
    border-radius: 0.75em;
    background: linear-gradient(321.16deg, #ff8f80 0%, #fe5236 71.4%);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .serviceTitle {
    font-size: 1.2vw;
    color: white;
    text-align: center;
    font-family: "Montserrat";
    margin-top: 1em;
  }
  .buttonDelete {
    background-color: #ffffff;
    cursor: pointer;
    color: chocolate;
    margin-top: 1vw;
    border: 0;
    width: 8vw;
    height: 2vw;
    font-family: "Montserrat", sans-serif;
    font-size: 0.8vw;
    border-radius: 0.4vw;
  }
  .buttonDelete:focus {
    background-color: #34324c;
    color: white;
    border: 0rem black;
  }
  .bottom-mini-card-Service {
    background-color: white;
    display: flex;
    flex-direction: column;
    border-radius: 1vw;
   
    width: 100%;
    margin-top:8vw;
    height: 50%;
  }
  .bottom-mini-div {
    display: flex;
    flex-direction: row;

    margin-left: 1rem;
    margin-top: 0rem;
  }
  .price {
      display: block;
    font-size:1vw; 
    margin-left: 0.5vw;
    margin-top: 0.3vw;
    font-weight: 600;
    font-family: Montserrat;
  }
  .addServiceMinus{
      display: flex;
    color: white;
    text-align: left;
    left: 30%;
    font-family: "Montserrat";
    margin-top: 3em;
    cursor: pointer;
  }
  .addServicePlus{
    display: flex;
    right: 30%;
    color: white;
    text-align: right;
    font-family: "Montserrat";
    margin-top: 3em;
    cursor: pointer;
  }
  .addServiceNumber{
    display: flex;
    right: 47.5%;
    color: white;
    text-align: right;
    font-family: "Montserrat";
    margin-top: 3em;
  }
  time{
    margin-left: 0.5vw;
    font-size: 1vw;
  }