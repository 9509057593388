@import url('https://fonts.googleapis.com/css2?family=Poiret+One&display=swap');
.newInvoice-mainContainer{
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100vw;
   
}
.newInvoice-section1{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 2vw;
}
.newInvoice-section1-logo{
    width: 20vw;
    max-width: 20vw;
    height: auto;
    max-height: 100%;
}
.newInvoice-section1-heading{
    display: flex;
    flex-direction: column;
    /* background-color: blue; */
   

}
.newInvoice-section1-heading-spanInnvo{
font-size: 2.5vw;
font-style: normal;
font-weight: 600;
letter-spacing: 0.5vw;
text-align: right;

}
.newInvoice-section1-heading-spanMohan{
    
font-size: 1.69vw;
font-style: normal;
font-weight: 300;
text-align: right;

}
.newInvoice-section1-heading-spanNumber{
font-size: 1.69vw;
font-style: normal;
font-weight: 300;
text-align: right;


}
.newInvoice-section2{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 2vw;
    margin-left: 3vw;
    margin-right: 3vw;
}
.newInvoice-section2-billed{
    display: flex;
    flex-direction: column;
}
.newInvoice-section2-billedSpan{
    
    font-style: normal;
    font-weight: 500;
    font-size: 2vw;
    text-transform: uppercase;    
    color: #C90000;
    margin-bottom: 1.5vw;
}
.newInvoice-section2-detailsSpan{
font-style: normal;
font-weight: 500;
font-size: 1.35vw;
color: #000000;
}
.barcode {
    display: flex;
    margin-top: -10%;
    max-width: 50%;
    /* transform: scale(0.5); */
}
.newInvoice-section2-packageDetails{
    display: flex;
    flex-direction: column;
}
.newInvoice-section2-invoiceDetails{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
/* .newInvoice-barcode{
    width: 5vw;
    height: 5vw;
} */
.newInvoice-section2-total{
    display: flex;
    flex-direction: column;
}
.section2-billedSpan-total{
    
font-style: normal;
font-weight: 500;
font-size: 3vw;
color: #000000;
}
.kariya-line{
    margin-top: 0px;   
    margin-bottom: 0px;
    border: 1px solid #000000;
    margin-left: 3vw;
    margin-right: 3vw;
}
.newInvoice-section3{
    display: flex;
    flex-direction: column;
}
.newInvoice-section3-heading{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 2vw;
    margin-left: 3vw;
    margin-right: 3vw;
}
.newInvoice-section3-headingContain{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 3vw;
    margin-right: 3vw;
}
.newInvoice-section2-billedSpanContain{
font-style: normal;
font-weight: 500;
font-size: 1.5vw;    
color: #000000;
}
.newInvoice-section3-GstDetails{
    display: flex;
    flex-direction: row;
    justify-content: space-between;    
    padding: 3vw
}
.section3-GstDetails-1stHalf{
    display: flex;
    flex-direction: column;
    flex: 1;
    /* background-color: red; */
}

.GstDetails-1stHalf-details{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.section3-GstDetails-2ndHalf{
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: flex-end;
    justify-content: center;
}
.newInvoice-section3-subTotal{
font-style: normal;
font-weight: 300;
font-size: 1.5vw;
line-height: 3vw;
color: #000000;
}
.newInvoice-section3-subTotal-div{
    display: flex;
    width: 70%;
    justify-content: space-between;
}
.newInvoice-section4{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 2vw;
    margin-left: 3vw;
    margin-right: 3vw;
}
.term-n-condtion-heading{
    
font-style: normal;
font-weight: 300;
font-size: 1.5vw;
color: #C80000;
}
.term-n-condtion-kuch-lekha{
    font-size: 1vw;
    font-style: normal;
    font-weight: 300;
    text-align: left;
    
}
.NewInvoice-section5{
    display: flex;    
    flex-direction: column;
    align-items: flex-end;    
    margin-left: 3vw;
    margin-right: 3vw;
}
.NewInvoice-section5-icons{
    display: flex;
    flex-direction: row;
}
.newInvoice-section5-logo{
    width: 5vw;
    height: 5vw;
    margin: 1vw;
}
.desgin{
font-family: Poiret One;
font-style: normal;
font-weight: normal;
font-size: 2vw;
line-height: 2.5vw;
text-align: center;
}
.haspr-span{
    font-style: normal;
    font-weight: normal;
    font-size: 1vw;
    line-height: 2.5vw;
    text-align: center;
    letter-spacing: 1vw;    
    color: #000000;
}


