html {
    font-size: 17px;
  }
  @media (max-width: 900px) {
    html {
      font-size: 15px;
    }
  }
  @media (max-width: 600px) {
    html {
      font-size: 13px;
      
    }
    .card-for-package-container {
      min-width:  100px;
         font-size: 1vw;
    }
  }
  .card-for-package-container-admin-package {
    width: 11.2vw;
    display: flex;
    flex-direction: column;
    font-size: 1vw;
  }
  .mother-card-admin-package {
    width: 12vw;
    border-radius: 1vw;
    margin-left: 1vw;
    margin-right: 0.5vw;
    margin-top: 2vw;
  }
  .card-upper-part-admin-package {
    background: linear-gradient(321.16deg, #ff8f80 0%, #fe5236 71.4%);
    border-radius: 1vw;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    white-space: pre-line;
    height: 9vw;
    color: white;
    font-size: 1.1vw;
    text-align: center;
    position: relative;
  }
  
  .card-upper-part-green-admin-package {
    position: relative;
    background: linear-gradient(321.67deg, #77dd77 0%, #1b533e 100%);
    /* border-top-left-radius: 1rem;
    border-top-right-radius: 1rem; */
    border-radius: 1vw;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    white-space: pre-line;
    /* height: 10rem; */
    height: 9vw;
    color: white;
    font-size: 1.1vw;
    text-align: center;
  }
  .card-top-button-admin-package {
    cursor: pointer;
    border-radius: 0.5vw;
  }
  .card-top-button-admin-package text {
    background-color: #fff;
    color: #000;
    mix-blend-mode: screen;
    font-weight: 900;
    padding-left: 2vw;
    padding-right: 2vw;
    padding-top: 0.2vw;
    padding-bottom: 0.2vw;
    border-radius: 0.4vw;
    font-size: 0.9vw;
    margin: 0;
    transition-duration: 0.1s;
  }
  .card-top-button-admin-package text:hover {
    background-color: #333333;
    color: #fff;
    mix-blend-mode: multiply;
  
    font-weight: 1000;
    padding-left: 2vw;
    padding-right: 2vw;
    padding-top: 0.2vw;
    padding-bottom: 0.2vw;
    border-radius: 0.5vw;
    font-size: 1vw;
    margin: 0;
    opacity: 0.9;
    box-shadow: 10px 10px 25px -10px rgba(0, 0, 0, 1);
  }
  .card-top-button-admin-package:hover {
    transform: scale(1.1);
  }
  .card-lower-part-admin-package {
    background: white;
    display: flex;
    flex-direction: column;
    border-bottom-left-radius: 1vw;
    border-bottom-right-radius: 1vw;
    height: 10vw;
    margin-top: -1vw;
    padding: 1vw;
    justify-content: center;
  }
  .card-mini-div-admin-package {
    display: flex;
    align-items: center;
    padding: 0.4vw;
  }
  .card-mini-text-admin-package {
    margin-right: auto;
    margin-left: 1vw;
    font-weight: 500;
    font-size: 0.9vw;
    
  }
  .card-top-text-admin-package {
    width: min-intrinsic; /* old Chrome, Safari */
    width: -webkit-min-content; /* less old Chrome, Safari */
    width: -moz-min-content; /* current Firefox */
    width: min-content; /* current Chrome, Safari; not IE or Edge */
  }
  .card-top-count-admin-package{
    position: absolute;
    top: 0.2vw;
   right: 0.2vw;
   background-color: white;
   color: #459258;
   border-radius: 50%;
   padding: 1vw;
   font-size: 1vw;
   width: 1vw;
   height: 1vw;
   display: flex;
   justify-content: center;
   align-items: center;
 
  }
  
  .card-top-button-selected-admin-package {
    cursor: pointer;
    border-radius: 0.5vw;
  }
  .card-top-button-selected-admin-package text {
    background-color: #333333;
    color: #fff;
    font-weight: bolder;
    padding-left: 2vw;
    padding-right: 2vw;
    padding-top: 0.1vw;
    padding-bottom: 0.1vw;
    border-radius: 0.5vw;
    font-size: 1vw;
    margin: 0;
    opacity: 0.9;
    box-shadow: 10px 10px 25px -10px rgba(0, 0, 0, 1);
  }
  .card-top-button-selected-admin-package text:hover {
    background-color: #fff;
    color: #000;
    mix-blend-mode: screen;
    font-weight: bolder;
    padding-left: 2vw;
    padding-right: 2vw;
    padding-top: 0.2vw;
    padding-bottom: 0.2vw;
    border-radius: 0.5vw;
    font-size: 1vw;
    margin: 0;
    transition-duration: 0.1s;
  }
  @media only screen and (max-width: 600px) {
    .mother-card-admin-package {
        width: 20vw;
        height: 15vh;
        border-radius: 1vw;
        margin-left: 1vw;
        margin-right: 0vw;
        margin-top: 0vw;
        background-color: crimson;
      }
      .card-for-package-container-admin-package {
        width: 20vw;        
        display: flex;
        flex-direction: column;
        font-size: 8vw;
        background-color: yellow;
      }
      .card-upper-part-green-admin-package {
        position: relative;
        
        border-radius: 1vw;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        white-space: pre-line;
        
        height: 15vw;
        color: white;
        font-size: 2.5vw;
        text-align: center;
        
      }
      .card-top-count-admin-package{
        position: absolute;
        top: 0.2vw;
       right: 0.2vw;
       background-color: white;
       color: #459258;
       border-radius: 50%;
       padding: 1vw;
       font-size: 3vw;
       width: 4vw;
       height: 4vw;
       display: flex;
       justify-content: center;
       align-items: center;
     
      }
      .card-lower-part-admin-package {
       
        display: flex;
        flex-direction: column;
        border-bottom-left-radius: 1vw;
        border-bottom-right-radius: 1vw;
        height: 13vw;
        margin-top: -1vw;
        padding: 1vw;
        justify-content: center;
      }
      .card-mini-text-admin-package {
        margin-right: auto;
        margin-left: 1vw;
        font-weight: 400;
        font-size: 2vw;
      }
  }
  