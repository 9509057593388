.service-container-package {
  height: 100%;
  width: 100%;
  background-color: white;
}
.service-header-package {
  display: flex;
  align-items: center;
  text-align: center;
  font-family: "Montserrat";
  font-size: xx-large;
  width: 100%;
}
.servicesSection {
  display: inline-flexbox;
}
.service-header-package::before {
  content: "";
  flex: 3;
  border-bottom: 0.15rem solid #fe5236;
}
.service-header-package::after {
  content: "";
  flex: 1;
  border-bottom: 0.15rem solid #fe5236;
}
.service-header-package::before {
  margin-right: 0.5em;
}
.service-header-package::after {
  margin-left: 0.5em;
}
.service-box-display {
  display: grid;

  grid-template-rows: repeat(100, 1fr);
  grid-template-columns: repeat(3, 1fr);

  grid-gap: 2rem;
  overflow: scroll;
}

.drawerItemLeftPack {
  display: inline-block;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  background: white;
  margin-top: 1px;
  margin-bottom: 1px;
  margin-left: 1px;
  cursor: pointer;
  color: rgba(52, 50, 76, 0.8);
}
.drawerActiveColorPack {
  display: inline-block;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  background: #fe5236;
  margin-top: 1px;
  margin-bottom: 1px;
  margin-left: 1px;
  cursor: pointer;
  color: white;
}
.drawerItemLeftPack:hover {
  width: 100%;
  height: 100%;
  background: #fe5236;
  cursor: pointer;
  color: white;
}
.drawerActiveColorPack:hover {
  width: 100%;
  height: 100%;
  background: #fe5236;
  cursor: pointer;
  color: white;
}
.aniDraw {
  margin-left: 0;
  transition: 0.3s;
}
.anDraw:after {
  margin-left: -100%;
  transition: all 0.3s;
}
