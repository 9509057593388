.calContainer {
  border-radius: 8px;
  background: white;
  flex-direction: column;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  text-align: center;
  padding-top: 4%;
}
.headingH1 {
  font-size: 70px;
  font-weight: 700;
  color: #34324c;
}
.headingH2 {
  font-weight: 600;
  font-family: Montserrat;
  font-weight: 600;
}
.month{
  font-weight: 700;
  font-family: Montserrat;
  color: #34324c
}
.year{
  font-weight: 700;
  font-family: Montserrat;

}
.dot1 {
  height: 15px;
  width: 15px;
  background-color: #34324c;
  border-radius: 100%;
}
.dot2 {
  height: 15px;
  width: 15px;
  background-color: #34324c;
  border-radius: 100%;
}
