.cardToAdd-container {
  display: flex;
  flex-direction: column;
  height: 15vh;
  background: linear-gradient(321.16deg, #ff8f80 0%, #fe5236 71.4%);
  padding: 1rem;
  color: white;
  font-family: "Montserrat";
  /* justify-content: center;
  align-items: center; */
  border-radius: 0.75em;
}
.bgCardAdder{
  filter: drop-shadow(0px 8px 25px rgba(0, 0, 0, 0.05));
  width: 330px;
  height: 280px;margin-left: 10px;margin-right: 10px;margin-bottom: 20px;
}
.topCardStuff{
  background: linear-gradient(180deg, #FF8F80 0%, #FE5236 100%);
border-radius: 12px 12px 0px 0px;
}
.bottomCardStuff{
  border-radius: 0px 0px 12px 12px;
}
.minusClick{
  color: #595959;
  background: #EEEEEE;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.minusClick:hover{
  color: #EEEEEE;
  background: #595959;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.plusClick{
  color: #595959;
  background: #EEEEEE;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.plusClick:hover{
  color: #EEEEEE;
  background: #595959;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

