.shadowBGAdmin {
    box-shadow: 0px 25px 65px 10px rgba(0, 0, 0, 0.15);
  }
  .inputAdmin {
    border: none;
    -webkit-appearance: none;
    padding: 5px;
    border-radius: 6px;
    font-family: "Montserrat";
  }
  
  .inputAdmin:hover {
    outline: none;
  }
  .inputAdmin:active {
    outline: none;
  }
  .inputAdmin:focus {
    outline: none;
  }
  .inputAdmin[type="text"] {
    font-size: 1rem;
    font-family: Montserrat;
    font-weight: 600;
  }
  .inputAdmin[type="number"] {
    font-size: 1rem;
    font-family: Montserrat;
    font-weight: 600;
  }
  .inputAdmin {
    font-size: 1rem;
    font-family: Montserrat;
    font-weight: 600;
  }
  
  .field-icon-admin {
    background-color: #c5c5c5;
    border-radius: 6px;
    justify-content: center;
    align-content: center;
    padding: 10px;
    font-weight: bold;
  }
  .textInputEmail{
    font-family: Montserrat;
  font-style: normal;
  font-weight: 900;
  color: #F49927;
  text-align: center;
  }
  .textInputPassword{
    font-family: Montserrat;
  font-style: normal;
  font-weight: 900;
  color: #F28642;
  text-align: center;
  }
  .buttonForward{
    cursor: pointer;
    filter: drop-shadow(0px 0px 0px rgba(255, 255, 255, 0));
    transition: 0.2s;
    transform: scale(1);
  }
  .buttonForward:hover{
    cursor: pointer;
    filter: drop-shadow(0px 0px 15px rgba(255, 255, 255, 0.75));
    transform: scale(1.15);
  }
  
  