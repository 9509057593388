* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat";
  font-weight: 600;
} 
.serviceGridWork{
  display: grid;
  height: 80%;
  width: 100%;
  grid-template-rows: repeat(2, 2.5rem);
  grid-template-columns: repeat(auto-fit, 20rem);
  align-items: center;
  justify-items: center;
  grid-gap: 0.75rem;
  padding: 2%;
  margin-left: 1.5%;
  margin-top: -20px;
}

.ActiveCard{
  background-color:  #fe5236;
  height: 90%;
  width: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: aliceblue;
}
.ActiveCard:hover{
  
  cursor: pointer;  
}

