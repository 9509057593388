.field-container-bg {
  display: flex;
}

input {
  border: none;
  -webkit-appearance: none;
  padding: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  font-family: "Montserrat";
  width: 15rem;
}

input:hover {
  outline: none;
}
input:active {
  outline: none;
}
input:focus {
  outline: none;
}
input[type="text"] {
  font-size: 1rem;

  font-family: Montserrat;
  font-weight: 600;
}
input[type="number"] {
  font-size: 1rem;

  font-family: Montserrat;
  font-weight: 600;
}
input {
  font-size: 1rem;

  font-family: Montserrat;
  font-weight: 600;
}

.field-icon-Left {
  display: flex;
  background-color: #c5c5c5;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding: 10px;
  text-align: center;
}
