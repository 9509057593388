* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.drop-container {
  display: flex;
}

.drop-icon {
  display: flex;
  background-color: #c5c5c5;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding: 10px;
  text-align: center;
}

