.top-tap-bar-invent {
    display: flex;
    flex-direction: row;
    width: 50%;
    height: 5.5vh;
    background-color: white;
    margin-left: 4%;
    margin-top: 2%;
    border-radius: 0.75vw;
    justify-content: center;
    align-items: center;
    /* border-radius: 10px; */
  }
  .ActiveCard-customer-invent {
    background-color: #fe5236;
    height: 5.5vh;
    border-radius: 0.75vw;
    align-items: center;
    justify-content: center;
    width: 15vw;
    display: flex;
    color: aliceblue;
    cursor: pointer;
  }
  .InactiveCard-invent {
    color: grey;
    border-radius: 0.75vw;
    height: 5.5vh;
    border-radius: 0.75vw;
    align-items: center;
    justify-content: center;
    width: 15vw;
    display: flex;
    cursor: pointer;
  }
  .InactiveCard-invent:hover {
    color: #fe5236;
  }
  .ActiveCard-customer-invent:after {
    transition: all 1s;
  }
  .cust-box-customer {
    display: grid;
    height: 80%;
    width: 100%;
    margin-top: -10px;
    grid-template-rows: repeat(2, 2.5rem);
    grid-template-columns: repeat(auto-fit, 20rem);
    align-items: center;
    justify-items: center;
    grid-gap: 2rem;
    padding: 3%;
  }
  .button-Customer {
    /* position: absolute;
    bottom: 4%;
    right: 4%; */
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-top: 2.5%;
    margin-right: 4%;
  }
  .check-details {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    height: 10vh;
    width: 100%;
    margin-top: 9px;
    margin-left: 4%;
  }
  .SearchCustomer-div {
    cursor: pointer;
  }

  .All-edit-field-div {
    display: grid;
    height: 80%;
    width: 100%;
    margin-top: -10px;
    grid-template-rows: repeat(2, 2.5rem);
    grid-template-columns: repeat(auto-fit, 20rem);
    align-items: center;
    justify-items: center;
    grid-gap: 2rem;
    padding: 3%;
  }
  .Update-Customer-div {
    cursor: pointer;
    width: 300px;
    height: 50px;
    display: flex;
    align-self: center;
    margin-left: 53vw;
  }
  .tile1cust-invent {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    padding-top: 6.5%;
    justify-content: center;
    font-size: 1.15vw;
  }
  .tile2cust-invent {
    font-family: Montserrat;
    font-weight: 400;
    padding-top: 2.5%;
  }
  .printerButton{
  font-size: 4vw;
  padding: 1vw;
  background-color: #EEEEEE;
  border-radius: 150px;
  cursor: pointer;
  transition: 0.2s;
  }
  .printerButton:hover{
    background-color: gray;
    border-radius: 150px
  }

  @media only screen and (max-width: 600px) {
    .top-tap-bar-invent {

      width: 90%;
      height: 5.5vh;
      background-color: white;
      margin-left: 4%;
      margin-top: 2%;
      border-radius: 0.75vw;
      justify-content: center;
      align-items: center;
      /* border-radius: 10px; */
    }
    .ActiveCard-customer-invent {
      background-color: #fe5236;
      height: 5.5vh;
      border-radius: 0.75vw;
      align-items: center;
      justify-content: center;
      width: 35%;
      display: flex;
      color: aliceblue;
      cursor: pointer;
    }
    .InactiveCard-invent {
      color: grey;
      border-radius: 0.75vw;
      height: 5.5vh;
      border-radius: 0.75vw;
      align-items: center;
      justify-content: center;
      width: 35%;
      display: flex;
      cursor: pointer;

    }
    .tile1cust-invent {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 700;
      padding-top: 6.5%;
      justify-content: center;
      font-size: 3vw;
    }
    .tile2cust-invent {
      font-family: Montserrat;
      font-weight: 400;
      padding-top: 2.5%;
    }
  }
