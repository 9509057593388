.main-container-div {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-items: center;
  justify-self: center;
  justify-content: center;
  /* background: #eeeeee; */
}
.invoice-div {
  margin-top: 200vh;
  display: flex;
  bottom: 0;
}

.mainSabsePeeche {
  border-radius: 24px;
  display: flex;
  margin-left: 2.5%;
  justify-content: flex-start;
  flex-direction: row;
}
.drawerItemLeft {
  display: inline-block;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  background: white;
  margin-top: 1px;
  margin-bottom: 1px;
  margin-left: 1px;
  cursor: pointer;
  color: rgba(52, 50, 76, 0.8);
}
.drawerActiveColor {
  display: inline-block;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  background: #fe5236;
  margin-top: 1px;
  margin-bottom: 1px;
  margin-left: 1px;
  cursor: pointer;
  color: white;
}
.drawerItemLeft:hover {
  width: 100%;
  height: 100%;
  background: #fe5236;
  cursor: pointer;
  color: white;
}
.drawerActiveColor:hover {
  width: 100%;
  height: 100%;
  background: #fe5236;
  cursor: pointer;
  color: white;
}

.servicesSection {
  padding-left: 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: scroll;
}
.scrollDownPackages {
  position: absolute;
  top: 84%;
  right: -5%;
  width: 200px;
  height: 100px;
  transition: 0.2s;
  cursor: pointer;
}
.scrollDownPackages:hover {
  position: absolute;
  top: 84%;
  right: -1%;
  width: 200px;
  height: 100px;
}
.renewWhat{
  font-size: 1vw;
  text-shadow: transparent;
  text-decoration-line: none;
  transition: 0.1s;
}
.renewWhat:hover{
  font-size: 1.25vw;
  text-shadow: black;
  text-decoration-line: underline;
}





.bookingBG {
  width: 6vw;
  height: 2vw;
  background: #c4c4c4;
  border-radius: 100%;
}
.circle2 {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 1.5vw;
  font-family: Montserrat;
  font-weight: 700;
  color: white;
  border-radius: 100%;
  background: #e52520;
  width: 1.5vw;
  height: 1.5vw;
}
.bookingsButton2 {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 0.65vw;
  line-height: 100%;
  color: #233d34;
  width: 10vw;
  background: #c4c4c4;
  border-radius: 2vw;
  cursor: pointer;
  filter: invert(0);
  transition: 0.2s;
}
.bookingsButton2:hover {
  cursor: pointer;
  filter: invert(1);
}
.glass2 {
  overflow-y: auto !important;
  width: 25vw;
  height: 0px;
  position: absolute;
  transition: 0.5s;
  margin-top: 1vw;
  background: rgba(255, 255, 255, 0.6);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 1.5vw;
}
.glassActive2 {
  height: 30%;
  transition: 0.5s;
}

.hrline {
  border: solid black;
  border-radius: 5px;
  border-width: 0.1px;
  opacity: 0.2;
  width: 88%;
}

