.shadowBG {
  box-shadow: 0px 25px 65px 10px rgba(0, 0, 0, 0.12);
}
.logo {
  position: absolute;
  left: 30%;
  right: 58.28%;
  top: 9%;
  transition: 0.8s;
}
.logo2 {
  position: absolute;
  left: 8%;
  top: 12%;
  transition: 0.8s;
}
.logoMobile {
  /* position: absolute;
  left: 4%;
  top: 4%; */
}
.bookingBG {
  width: 6vw;
  height: 2vw;
  background: #c4c4c4;
  border-radius: 100%;
}
.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 1.5vw;
  font-family: Montserrat;
  font-weight: 700;
  color: white;
  border-radius: 100%;
  background: #e52520;
  width: 1.5vw;
  height: 1.5vw;
}
.bookingsButton {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 0.65vw;
  line-height: 100%;
  color: #233d34;
  width: 10vw;
  background: #c4c4c4;
  border-radius: 2vw;
  cursor: pointer;
  position: absolute;
  right: 25.5%;
  top: -2%;
  filter: invert(0);
  transition: 0.2s;
}
.bookingsButton:hover {
  cursor: pointer;
  position: absolute;
  right: 25%;
  top: -2%;
  filter: invert(1);
}
.glass {
  position: absolute;
  overflow-y: auto !important;
  top: 10%;
  right: 13%;
  width: 25vw;
  height: 0px;
  transition: 0.5s;
  background: rgba(255, 255, 255, 0.6);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 1.5vw;
}
.glassActive {
  height: 70%;
  transition: 0.5s;
}
.hrline {
  border: solid black;
  border-radius: 5px;
  border-width: 0.1px;
  opacity: 0.2;
  width: 88%;
}
.adminButton {
  cursor: pointer;
  position: absolute;
  right: 7.5%;
  top: -2%;
  filter: invert(0);
  transition: 0.2s;
}
.adminButton:hover {
  cursor: pointer;
  position: absolute;
  right: 7%;
  top: -2%;
  filter: invert(1);
}
.vehNoLabel {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  color: #233d34;
}
.vehNoField {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border: 2px;
  border-color: #ffb921;
  border-style: solid;
  border-radius: 8px;
  color: #233d34;
}
.orLabel {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 900;
  font-size: 3vw;
  line-height: 75px;
  text-align: center;
  color: #efefef;
}
.orLabelMobile {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 900;
  font-size: 8vw;
  line-height: 75px;
  text-align: center;
  color: #efefef;
}
.submitButton {
  cursor: pointer;
  filter: grayscale(1);
}
.submitButton:hover {
  filter: grayscale(0);
}
.cancelPackDialog {
  cursor: pointer;
  filter: grayscale(1);
  transition: 0.3s;
}
.cancelPackDialog:hover {
  filter: grayscale(0);
}
.haspr {
  position: absolute;
  bottom: 2%;
  cursor: pointer;
}
.button1 {
  position: absolute;
  left: 7.34%;
  top: 45%;
  background: linear-gradient(180deg, #ffb921 0%, #f07063 100%);
  cursor: pointer;
  transition: 0.4s;
}
.button1:hover {
  background: linear-gradient(180deg, #00000099 -25%, #000000 100%);
  cursor: pointer;
}
.button1Mobile {
  position: absolute;
  left: 10%;
  top: 38%;
  background: linear-gradient(180deg, #ffb921 0%, #f07063 100%);
  cursor: pointer;
}
.button2 {
  position: absolute;
  left: 28%;
  top: 45%;
  background: linear-gradient(180deg, #ffb921 0%, #f07063 100%);
  cursor: pointer;
  transition: 0.4s;
}
.button2:hover {
  background: linear-gradient(180deg, #00000099 -25%, #000000 100%);
  cursor: pointer;
}
.button2Mobile {
  position: absolute;
  left: 50%;
  top: 23%;
  background: linear-gradient(180deg, #ffb921 0%, #f07063 100%);
  cursor: pointer;
}
.button2Mobile:active {
  background: linear-gradient(180deg, #000000 0%, #000000 100%);
}
.mainSVG {
  position: absolute;
  right: 0%;
  bottom: 0%;
  border-bottom-right-radius: 25px;
  transition: 1.5s;
}
.mainSVGMobile {
  position: absolute;
  right: 0%;
  bottom: 0%;
  border-bottom-right-radius: 25px;
}
.button1Title1 {
  display: block;
  font-style: normal;
  font-family: Montserrat;
  font-weight: 300;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  padding-top: 1vw;
}
.button1Title1Mobile {
  display: inline-block;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 300;
  cursor: pointer;
  line-height: 1.35rem;
  text-align: center;
  color: #ffffff;
  top: 37%;
}
.button1Title2 {
  display: block;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 900;
  cursor: pointer;
  text-align: center;
  color: #ffffff;
}
.button1Title2Mobile {
  display: inline-block;
  font-family: Montserrat;
  font-style: normal;
  cursor: pointer;
  font-weight: 900;
  line-height: 1.35rem;
  text-align: center;
  color: #ffffff;
  top: 37%;
}
.animationLogo {
  animation: fade 1.5s ease;
}
@keyframes fade {
  0% {
    width: 20%;
  }
}
.animationLogo2 {
  animation: fade2 1.5s ease;
}
@keyframes fade2 {
  0% {
    width: 30%;
  }
}
.animationButton1 {
  animation: bounce 1.9s ease;
}
@keyframes bounce {
  0% {
    transform: scale(0.85);
  }
}
.animationCars {
  animation: bounce2 2s ease;
}
@keyframes bounce2 {
  0% {
    height: 85%;
  }
}
.adminLoginAnimation {
  animation: bounce3 2s ease-in-out;
}
@keyframes bounce3 {
  0% {
    right: 5%;
  }
}
.animationHaspr {
  animation: bounce4 1.9s ease;
}
@keyframes bounce4 {
  0% {
    width: 25%;
    opacity: 0;
    filter: invert(1);
  }
}
