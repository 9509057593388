.container-iot-tio{
  width: 92%;
  height: 92%;
  background: #EEEEEE;
  border-radius: 24px;
  flex-direction: row;
  }
  .drawer{
  
  width: 75%;
  height: 100%;
  left: 0%;
  top: 0%;
  background: #EEEEEE;
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
  }
  .tempBill{
  
  width: 25%;
  height: 100%;
  right: 0%;
  top: 0%;
  background: #DBDBDB;
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
  }
  .drawerButtons{
    
    width: 25%;
    height: 100%;
    top: 0px;
    left: 1px;
    flex-direction: column;
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;
  }
  .title1{
  position: relative;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  top: 50%;
  left: 0%;
  text-align: center;
  margin-top: 1.25px;
  margin-bottom: 1.5px;
  }
  .title2{
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  }
  .DrawerItem2{
    flex-direction: row;
    background: white;
    line-height: 19.25vh;
    margin-top: 1.5px;
    margin-bottom: 1.5px;
    cursor: pointer;
    color: rgba(52, 50, 76, 0.8);
  }
  .DrawerItem2Active{
    flex-direction: row;
    background:  #FE5236;
    line-height: 19.25vh;
    margin-top: 1.5px;
    margin-bottom: 1.5px;
    cursor: pointer;
    color:white;
  }
  .DrawerItem2:hover {
    background: #FE5236;
    cursor: pointer;
    color: white;
  }
  .DrawerItem3{
    flex-direction: row;
    background: white;
    line-height: 19.25vh;
    margin-top: 1.5px;
    margin-bottom: 1.5px;
    cursor: pointer;
    color: rgba(52, 50, 76, 0.8);
  }
  .DrawerItem3Active{
    flex-direction: row;
    background:  #FE5236;
    line-height: 19.25vh;
    margin-top: 1.5px;
    margin-bottom: 1.5px;
    cursor: pointer;
    color:white;
  }
  .DrawerItem3:hover {
    background: #FE5236;
    cursor: pointer;
    color: white;
  }
  .DrawerItem4{
    flex-direction: row;
    background: white;
    line-height: 19.25vh;
    margin-top: 1.5px;
    margin-bottom: 1.5px;
    cursor: pointer;
    color: rgba(52, 50, 76, 0.8);
  }
  .DrawerItem4Active{
    flex-direction: row;
    background:  #FE5236;
    line-height: 19.25vh;
    margin-top: 1.5px;
    margin-bottom: 1.5px;
    cursor: pointer;
    color:white;
  }
  .DrawerItem4:hover {
    background: #FE5236;
    cursor: pointer;
    color: white;
  }
  .topLeftDrawerItem{
    flex-direction: row;
    background: white;
    line-height: 19.25vh;
    margin-top: 1.5px;
    margin-bottom: 1.5px;
    border-top-left-radius: 24px;
    cursor: pointer;
    color: rgba(52, 50, 76, 0.8);
  }
  .topLeftDrawerItemActive{
    flex-direction: row;
    background:  #FE5236;
    line-height: 19.25vh;
    margin-top: 1.5px;
    margin-bottom: 1.5px;
    border-top-left-radius: 24px;
    cursor: pointer;
    color:white;
  }
  .topLeftDrawerItem:hover {
    background: #FE5236;
    cursor: pointer;
    color: white;
  }
  .bottomLeftDrawerItem{
    flex-direction: row;
    background: white;
    line-height: 19.25vh;
    margin-top: 1.5px;
    margin-bottom: 1.5px;
    border-bottom-left-radius: 24px;
    cursor: pointer;
    color: rgba(52, 50, 76, 0.8);
  }
  .bottomLeftDrawerItemActive{
    flex-direction: row;
     background: #FE5236;
    line-height: 19.25vh;
    margin-top: 1.5px;
    margin-bottom: 1.5px;
    border-bottom-left-radius: 24px;
    cursor: pointer;
      color: white;
  }
  .bottomLeftDrawerItem:hover {
    background: #FE5236;
    cursor: pointer;
    color: white;
  }
  .services{
    
  
    padding-left: 15px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    right: 0px;
    width: 74.75%;
    height: 100%;
    overflow: scroll;
  
  
  }
  h1{
    font-size: 25px;
    margin-left: 12%;
  }