.containerBG {
width: 250px;
height: 175px;
background-image: url('./kmBar.svg');
display: flex;
align-items: center;
justify-content: center;
}

.imageBG { 
  position: absolute;
   width: 250px;;
}

.distance { 
position: absolute;
width: 100px;
height: 75px;
left: 60px;
  top: 2%;
  font-size: 1.1rem;
  font-family: Montserrat;
  font-weight: 800;
  text-align: right;
  color: white;
  display: flex;

}
.imageBg{
  position: absolute;
  width: 200px;
  left: 60px;
  top: 2%;
}

.kilometer {
position: absolute;
  top: 2%;
  left: 150px;
  font-size: 1.1rem;
  font-family: Montserrat;
  font-weight: 400;
  text-align: right;
  color: white;
  display: flex;
  height: 100%;
}