.main-div {
  background-color: #eeeeee;
  display: flex;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
}
.contain {
  width: 100%;
  height: 100%;
  background: #eeeeee;
  display: flex;
  flex-direction: row;
}
.drawerNewButtons {
  width: 22.5%;
  height: 100%;
}
.title1new {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 7vh;
}
.title2new {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
}
.servicesNewSetion {
  display: block;
  width: 100%;
  height: 100%;
  overflow: scroll;
}
h1 {
  font-size: 25px;
  margin-left: 12%;
}
.cardsAdmin {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: scroll;
}
::-webkit-scrollbar {
  display: none;
}
.DrawerItem {
  width: 100%;
  flex-direction: row;
  background: white;
  margin-top: 1.5px;
  margin-bottom: 1.5px;
  cursor: pointer;
  height: 16.66%;
  color: rgba(52, 50, 76, 0.8);
}
.DrawerItemActive {
  width: 100%;
  flex-direction: row;
  background: #fe5236;
  margin-top: 1.5px;
  margin-bottom: 1.5px;
  height: 16.66%;
  cursor: pointer;
  color: white;
}
.DrawerItem:hover {
  background: #fe5236;
  cursor: pointer;
  color: white;
  transition: all 0.4s;
}
.DrawerItemMini {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: white;
  height: 16.66%;
  color: rgba(52, 50, 76, 0.8);
  padding: 0.5rem;
}
.DrawerItemActiveMini {
  padding: 0.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fe5236;

  height: 16.66%;
  cursor: pointer;
  color: white;
}
.DrawerItemMini:hover {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fe5236;
  cursor: pointer;
  color: white;
  transition: all 0.4s;
}
.box {
  display: grid;
  height: 50vh;
  width: 100%;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(auto-fit, 1fr);
  align-items: center;
  justify-items: center;

  grid-gap: 2rem;
  padding: 2rem;
}
.textInputEmail {
  border-radius: 100px;
}
.textInputPassword {
  border-radius: 100px;
}

.Top-tap-service {
  display: flex;
  flex-direction: row;
  width: 75vw;
  height: 10vh;
  background-color: white;
  margin-top: 2vh;
  margin-left: 4vw;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
}
.ActiveCard-service {
  background-color: #fe5236;
  height: 10vh;
  align-items: center;
  justify-content: center;
  width: 18.75vw;
  display: flex;
  transition: 1s;

  color: aliceblue;
  border-radius: 1vw;

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.InactiveCard {
  padding-left: 15px;
  padding-right: 15px;
  margin: auto;

  color: grey;
}
.ActiveCard-service {
  cursor: pointer;
}
.ActiveCard-service:after {
  transition: all 1s;
}

.DrawerItemEmp {
  width: 100%;
  flex-direction: row;
  background: white;
  margin-bottom: 1.5px;
  cursor: pointer;
  height: 50%;
  color: rgba(52, 50, 76, 0.8);
}
.DrawerItemActiveEmp {
  width: 100%;
  flex-direction: row;
  background: #fe5236;
  margin-bottom: 1.5px;
  height: 50%;
  cursor: pointer;
  color: white;
}
.DrawerItemEmp:hover {
  background: #fe5236;
  cursor: pointer;
  color: white;
  transition: all 0.8s;
}
.flo {
  display: none;
  position: absolute;
  z-index: 13;
}
@media only screen and (max-width: 600px) {
  .drawerNewButtons {
    display: none;
  }
  .flo {
    display: block;
    z-index: 6;
    bottom: 0.5%;
    right: 0.5%;
  }
}
