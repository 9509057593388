.card-container {
  background-color: white;
  position: relative;
  width: 200px;
  height: 300px;
  margin: 15px ;
  border-radius: 16px;
  filter: drop-shadow(0px 15px 30px rgba(0, 0, 0, 0.12));

}

.mini-card {
  background-color: chocolate;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 130px;
  border-radius: 0.75em;

  background: linear-gradient(321.16deg, #ff8f80 0%, #fe5236 71.4%);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.h3 {
  position: absolute;
  color: white;
  text-align: center;
  font-family: "Montserrat";
  margin-top: 0.3em;
}
.button {
  position: absolute;
  background-color: #ffffff;
  cursor: pointer;
  margin-top: 6em;
  color: chocolate;
  border: 0;
  width: 11em;
  height: 2em;
  font-family: "Montserrat", sans-serif;
  font-size: 0.8rem;
  border-radius: 8px;
}
.button:focus {
  background-color: #34324c;
  color: white;
  border: 0rem black;
}
.bottom-mini-card {
  position: absolute;
  background-color: white;
  display: flex;
  border-radius: 0.75em;
  /* justify-content: center; */
  /* align-items: center; */
  flex-direction: column;
  width: 100%;
  margin-top: 7.5rem;
  height: 50px;
}
.bottom-mini-div {
  display: flex;
  margin-left: 1rem;
  margin-top: 0.8rem;
}
.h6 {
  margin-left: 1rem;
  margin-top: 0.3rem;
  font-weight: 700;
  font-family: Montserrat;
}
