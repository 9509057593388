@import url(https://fonts.googleapis.com/css2?family=DM+Mono:wght@300;400;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=DM+Mono:wght@300;400;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=DM+Mono:wght@300;400;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poiret+One&display=swap);
.main-div {
  background-color: #eeeeee;
  display: flex;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
}
.contain {
  width: 100%;
  height: 100%;
  background: #eeeeee;
  display: flex;
  flex-direction: row;
}
.drawerNewButtons {
  width: 22.5%;
  height: 100%;
}
.title1new {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 7vh;
}
.title2new {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
}
.servicesNewSetion {
  display: block;
  width: 100%;
  height: 100%;
  overflow: scroll;
}
h1 {
  font-size: 25px;
  margin-left: 12%;
}
.cardsAdmin {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: scroll;
}
::-webkit-scrollbar {
  display: none;
}
.DrawerItem {
  width: 100%;
  flex-direction: row;
  background: white;
  margin-top: 1.5px;
  margin-bottom: 1.5px;
  cursor: pointer;
  height: 16.66%;
  color: rgba(52, 50, 76, 0.8);
}
.DrawerItemActive {
  width: 100%;
  flex-direction: row;
  background: #fe5236;
  margin-top: 1.5px;
  margin-bottom: 1.5px;
  height: 16.66%;
  cursor: pointer;
  color: white;
}
.DrawerItem:hover {
  background: #fe5236;
  cursor: pointer;
  color: white;
  transition: all 0.4s;
}
.DrawerItemMini {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: white;
  height: 16.66%;
  color: rgba(52, 50, 76, 0.8);
  padding: 0.5rem;
}
.DrawerItemActiveMini {
  padding: 0.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fe5236;

  height: 16.66%;
  cursor: pointer;
  color: white;
}
.DrawerItemMini:hover {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fe5236;
  cursor: pointer;
  color: white;
  transition: all 0.4s;
}
.box {
  display: grid;
  height: 50vh;
  width: 100%;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(auto-fit, 1fr);
  align-items: center;
  justify-items: center;

  grid-gap: 2rem;
  padding: 2rem;
}
.textInputEmail {
  border-radius: 100px;
}
.textInputPassword {
  border-radius: 100px;
}

.Top-tap-service {
  display: flex;
  flex-direction: row;
  width: 75vw;
  height: 10vh;
  background-color: white;
  margin-top: 2vh;
  margin-left: 4vw;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
}
.ActiveCard-service {
  background-color: #fe5236;
  height: 10vh;
  align-items: center;
  justify-content: center;
  width: 18.75vw;
  display: flex;
  transition: 1s;

  color: aliceblue;
  border-radius: 1vw;

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.InactiveCard {
  padding-left: 15px;
  padding-right: 15px;
  margin: auto;

  color: grey;
}
.ActiveCard-service {
  cursor: pointer;
}
.ActiveCard-service:after {
  transition: all 1s;
}

.DrawerItemEmp {
  width: 100%;
  flex-direction: row;
  background: white;
  margin-bottom: 1.5px;
  cursor: pointer;
  height: 50%;
  color: rgba(52, 50, 76, 0.8);
}
.DrawerItemActiveEmp {
  width: 100%;
  flex-direction: row;
  background: #fe5236;
  margin-bottom: 1.5px;
  height: 50%;
  cursor: pointer;
  color: white;
}
.DrawerItemEmp:hover {
  background: #fe5236;
  cursor: pointer;
  color: white;
  transition: all 0.8s;
}
.flo {
  display: none;
  position: absolute;
  z-index: 13;
}
@media only screen and (max-width: 600px) {
  .drawerNewButtons {
    display: none;
  }
  .flo {
    display: block;
    z-index: 6;
    bottom: 0.5%;
    right: 0.5%;
  }
}

.card-container-admin {
    background-color: white;
    position: relative;
    width: 15vw;
    height: 15vw;
    margin: 2vw ;
    border-radius: 1vw;
  }
  
  .mini-card-top {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 8vw;
    border-radius: 0.75em;
    background: linear-gradient(321.16deg, #ff8f80 0%, #fe5236 71.4%);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .serviceTitle {
    font-size: 1.2vw;
    color: white;
    text-align: center;
    font-family: "Montserrat";
    margin-top: 1em;
  }
  .buttonDelete {
    background-color: #ffffff;
    cursor: pointer;
    color: chocolate;
    margin-top: 1vw;
    border: 0;
    width: 8vw;
    height: 2vw;
    font-family: "Montserrat", sans-serif;
    font-size: 0.8vw;
    border-radius: 0.4vw;
  }
  .buttonDelete:focus {
    background-color: #34324c;
    color: white;
    border: 0rem black;
  }
  .bottom-mini-card-Service {
    background-color: white;
    display: flex;
    flex-direction: column;
    border-radius: 1vw;
   
    width: 100%;
    margin-top:8vw;
    height: 50%;
  }
  .bottom-mini-div {
    display: flex;
    flex-direction: row;

    margin-left: 1rem;
    margin-top: 0rem;
  }
  .price {
      display: block;
    font-size:1vw; 
    margin-left: 0.5vw;
    margin-top: 0.3vw;
    font-weight: 600;
    font-family: Montserrat;
  }
  .addServiceMinus{
      display: flex;
    color: white;
    text-align: left;
    left: 30%;
    font-family: "Montserrat";
    margin-top: 3em;
    cursor: pointer;
  }
  .addServicePlus{
    display: flex;
    right: 30%;
    color: white;
    text-align: right;
    font-family: "Montserrat";
    margin-top: 3em;
    cursor: pointer;
  }
  .addServiceNumber{
    display: flex;
    right: 47.5%;
    color: white;
    text-align: right;
    font-family: "Montserrat";
    margin-top: 3em;
  }
  time{
    margin-left: 0.5vw;
    font-size: 1vw;
  }
.package-screen-container {
  display: grid;
  height: 80vh;
  width: 100%;
  grid-template-rows: repeat(5, 1fr);
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10mm;
  overflow: scroll;
  align-self: center;
}
.button-Package {
  cursor: pointer;
  padding-left: 30px;
  position: absolute;
  bottom: 8%;
  right: 8%;
}
.Top-tap-package {
  display: flex;
  flex-direction: row;
  width: 80vw;
  height: 7vh;
  justify-content: flex-start;
  align-items: center;
}
.InactiveCardPack {
  height: 5.5vh;
  align-items: center;
  justify-content: center;
  width: 10vw;
  display: flex;
  color: gray;
  border-radius: 1vw;
  background-color: white;
}
.InactiveCardPack:hover {
  color: #fe5236;
}
.ActiveCard-package {
  background-color: #fe5236;
  height: 5.5vh;
  align-items: center;
  justify-content: center;
  width: 10vw;
  display: flex;
  color: aliceblue;
  border-radius: 1vw;
  cursor: pointer;
}
.ActiveCard-package:after {
  transition: all 2s;
}
.tile1Pack {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  margin-top: 10%;
  justify-content: center;
  font-size: 0.75vw;
}
.tile2Pack {
  font-family: Montserrat;

  font-weight: 400;
}
.drawerBackThing {
  border-radius: 1vw;
  display: flex;
  flex-direction: row;
  background-color: white;
  overflow: scroll;
}
.view-package-card {
  display: flex;
  flex-wrap: wrap;
  /* flex-flow: column wrap; */
  width: 75vw;
  height: 70vh;
  margin-top: 5vh;
  margin-left: 5vh;

  overflow: scroll;
}
.select-package-drop {
  display: flex;
  flex-wrap: wrap;
  width: 70vw;
  margin-left: 3.5vw;
}
.label-price {
  background-color: white;
  width: 15vw;
  margin-left: 1vw;
  padding-left: 1vw;
  display: flex;
  align-items: center;
}
.drop-div{
  /* background-color: chocolate; */
}
@media only screen and (max-width: 1024px) {
  .label-price {
    font-size: 10px;
    width: 13vw;
    padding-left: 0.5vw;
  }
  .select-package-drop {
    width: 100vw;
  }
}

@media only screen and (max-width: 600px) {
  .Top-tap-package {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 7vh;
    margin-left: 6%;
    justify-content: flex-start;
    align-items: center;
    border-radius: 1vw;
  }
  .drawerBackThing {
    border-radius: 1vw;
    width: 90%;
    background-color: white;
    overflow: scroll;
  }
  .ActiveCard-package {   
    height: 6vh;
    width: 60vw;
    display: flex;
    color: aliceblue;
    border-radius: 1vw;
    cursor: pointer;
    padding-left: 3px;
   
  }
  .tile1Pack {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    margin-top: 10%;
    justify-content: center;
    font-size: 12px;
  }
  .tile2Pack {
    font-family: Montserrat;  
    font-weight: 400;
  }
  .InactiveCardPack {    
    height: 6vh;   
    width: 60vw;    
    background-color: white;    
    
  }
  .drop-div{
    
    width: 90vw;
  }
  .label-price {
    background-color: white;
    margin-top: 1vh;
    height: 5vh;
    width: 30vw;
    margin-left: 1vw;
    padding-left: 1vw;
    display: flex;
    align-items: center;
    font-size: small;
  }
  .view-package-card {
    display: flex;
    
    /* flex-flow: column wrap; */
    width: 75vw;
    height: 70vh;
    margin-top: 5vh;
    margin-left: 5vh;
    
    overflow: scroll;
  }
  

}

.cardToAdd-container {
  display: flex;
  flex-direction: column;
  height: 15vh;
  background: linear-gradient(321.16deg, #ff8f80 0%, #fe5236 71.4%);
  padding: 1rem;
  color: white;
  font-family: "Montserrat";
  /* justify-content: center;
  align-items: center; */
  border-radius: 0.75em;
}
.bgCardAdder{
  -webkit-filter: drop-shadow(0px 8px 25px rgba(0, 0, 0, 0.05));
          filter: drop-shadow(0px 8px 25px rgba(0, 0, 0, 0.05));
  width: 330px;
  height: 280px;margin-left: 10px;margin-right: 10px;margin-bottom: 20px;
}
.topCardStuff{
  background: linear-gradient(180deg, #FF8F80 0%, #FE5236 100%);
border-radius: 12px 12px 0px 0px;
}
.bottomCardStuff{
  border-radius: 0px 0px 12px 12px;
}
.minusClick{
  color: #595959;
  background: #EEEEEE;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.minusClick:hover{
  color: #EEEEEE;
  background: #595959;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.plusClick{
  color: #595959;
  background: #EEEEEE;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.plusClick:hover{
  color: #EEEEEE;
  background: #595959;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}


.field-container-bg {
  display: flex;
}

input {
  border: none;
  -webkit-appearance: none;
  padding: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  font-family: "Montserrat";
  width: 15rem;
}

input:hover {
  outline: none;
}
input:active {
  outline: none;
}
input:focus {
  outline: none;
}
input[type="text"] {
  font-size: 1rem;

  font-family: Montserrat;
  font-weight: 600;
}
input[type="number"] {
  font-size: 1rem;

  font-family: Montserrat;
  font-weight: 600;
}
input {
  font-size: 1rem;

  font-family: Montserrat;
  font-weight: 600;
}

.field-icon-Left {
  display: flex;
  background-color: #c5c5c5;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding: 10px;
  text-align: center;
}

.button-container {
  position: relative;
  display: flex;
  border-radius: 1vw;
  transition: 0.4s;  
  align-items: center;
  background: white;
  cursor: pointer;
  -webkit-filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.085));
          filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.085));
}
.button-container:active {
  background: #fe5236;
}
.button-container:active > .titleForSubmit {
  color: white;
}
.button-container:active > .chotaArrow {
  fill: white;
}
.button-container:hover > .button-icon {
  width: 100%;
  justify-content: left;
  align-items: left;
  align-items: left;
  text-align: left;
  padding-left: 1vw;
  opacity: 0;
}
.button-container:hover > .titleForSubmit {
  margin-left: -2vw;
}
.button-container:hover > .chotaArrow {
  right: 2vw;
  -webkit-filter: invert(0);
          filter: invert(0);
}
.button-icon {
  position: absolute;
  transition: 0.4s;
  display: flex;
  left: 0;
  padding-left: 0px;
  background-color: #fe5236;
  border-radius: 1vw;
  height: 100%;
  width: 20%;
  justify-content: center;
  align-items: center;
  align-items: center;
  text-align: center;
  font-weight: bold;
  opacity: 1;
}
.titleForSubmit {
  position: absolute;
  width: 100%;
  transition: 0.4s;
  display: flex;
  justify-content: center;
  font-family: "Montserrat";
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  text-align: center;
  color: #34324c;
}
.chotaArrow {
  transition: 0.4s;
  position: absolute;
  right: 1.5vw;
  height: 25%;
  -webkit-filter: invert(1);
          filter: invert(1);
}

.TabBar_main__1teQJ {
  background-color: white;
  border-radius: 10px;
  padding: 0.3rem;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.drop-container {
  display: flex;
}

.drop-icon {
  display: flex;
  background-color: #c5c5c5;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding: 10px;
  text-align: center;
}


html {
    font-size: 17px;
  }
  @media (max-width: 900px) {
    html {
      font-size: 15px;
    }
  }
  @media (max-width: 600px) {
    html {
      font-size: 13px;
      
    }
    .card-for-package-container {
      min-width:  100px;
         font-size: 1vw;
    }
  }
  .card-for-package-container-admin-package {
    width: 11.2vw;
    display: flex;
    flex-direction: column;
    font-size: 1vw;
  }
  .mother-card-admin-package {
    width: 12vw;
    border-radius: 1vw;
    margin-left: 1vw;
    margin-right: 0.5vw;
    margin-top: 2vw;
  }
  .card-upper-part-admin-package {
    background: linear-gradient(321.16deg, #ff8f80 0%, #fe5236 71.4%);
    border-radius: 1vw;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    white-space: pre-line;
    height: 9vw;
    color: white;
    font-size: 1.1vw;
    text-align: center;
    position: relative;
  }
  
  .card-upper-part-green-admin-package {
    position: relative;
    background: linear-gradient(321.67deg, #77dd77 0%, #1b533e 100%);
    /* border-top-left-radius: 1rem;
    border-top-right-radius: 1rem; */
    border-radius: 1vw;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    white-space: pre-line;
    /* height: 10rem; */
    height: 9vw;
    color: white;
    font-size: 1.1vw;
    text-align: center;
  }
  .card-top-button-admin-package {
    cursor: pointer;
    border-radius: 0.5vw;
  }
  .card-top-button-admin-package text {
    background-color: #fff;
    color: #000;
    mix-blend-mode: screen;
    font-weight: 900;
    padding-left: 2vw;
    padding-right: 2vw;
    padding-top: 0.2vw;
    padding-bottom: 0.2vw;
    border-radius: 0.4vw;
    font-size: 0.9vw;
    margin: 0;
    transition-duration: 0.1s;
  }
  .card-top-button-admin-package text:hover {
    background-color: #333333;
    color: #fff;
    mix-blend-mode: multiply;
  
    font-weight: 1000;
    padding-left: 2vw;
    padding-right: 2vw;
    padding-top: 0.2vw;
    padding-bottom: 0.2vw;
    border-radius: 0.5vw;
    font-size: 1vw;
    margin: 0;
    opacity: 0.9;
    box-shadow: 10px 10px 25px -10px rgba(0, 0, 0, 1);
  }
  .card-top-button-admin-package:hover {
    transform: scale(1.1);
  }
  .card-lower-part-admin-package {
    background: white;
    display: flex;
    flex-direction: column;
    border-bottom-left-radius: 1vw;
    border-bottom-right-radius: 1vw;
    height: 10vw;
    margin-top: -1vw;
    padding: 1vw;
    justify-content: center;
  }
  .card-mini-div-admin-package {
    display: flex;
    align-items: center;
    padding: 0.4vw;
  }
  .card-mini-text-admin-package {
    margin-right: auto;
    margin-left: 1vw;
    font-weight: 500;
    font-size: 0.9vw;
    
  }
  .card-top-text-admin-package {
    width: min-intrinsic; /* old Chrome, Safari */
    width: -webkit-min-content; /* less old Chrome, Safari */
    width: -moz-min-content; /* current Firefox */
    width: min-content; /* current Chrome, Safari; not IE or Edge */
  }
  .card-top-count-admin-package{
    position: absolute;
    top: 0.2vw;
   right: 0.2vw;
   background-color: white;
   color: #459258;
   border-radius: 50%;
   padding: 1vw;
   font-size: 1vw;
   width: 1vw;
   height: 1vw;
   display: flex;
   justify-content: center;
   align-items: center;
 
  }
  
  .card-top-button-selected-admin-package {
    cursor: pointer;
    border-radius: 0.5vw;
  }
  .card-top-button-selected-admin-package text {
    background-color: #333333;
    color: #fff;
    font-weight: bolder;
    padding-left: 2vw;
    padding-right: 2vw;
    padding-top: 0.1vw;
    padding-bottom: 0.1vw;
    border-radius: 0.5vw;
    font-size: 1vw;
    margin: 0;
    opacity: 0.9;
    box-shadow: 10px 10px 25px -10px rgba(0, 0, 0, 1);
  }
  .card-top-button-selected-admin-package text:hover {
    background-color: #fff;
    color: #000;
    mix-blend-mode: screen;
    font-weight: bolder;
    padding-left: 2vw;
    padding-right: 2vw;
    padding-top: 0.2vw;
    padding-bottom: 0.2vw;
    border-radius: 0.5vw;
    font-size: 1vw;
    margin: 0;
    transition-duration: 0.1s;
  }
  @media only screen and (max-width: 600px) {
    .mother-card-admin-package {
        width: 20vw;
        height: 15vh;
        border-radius: 1vw;
        margin-left: 1vw;
        margin-right: 0vw;
        margin-top: 0vw;
        background-color: crimson;
      }
      .card-for-package-container-admin-package {
        width: 20vw;        
        display: flex;
        flex-direction: column;
        font-size: 8vw;
        background-color: yellow;
      }
      .card-upper-part-green-admin-package {
        position: relative;
        
        border-radius: 1vw;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        white-space: pre-line;
        
        height: 15vw;
        color: white;
        font-size: 2.5vw;
        text-align: center;
        
      }
      .card-top-count-admin-package{
        position: absolute;
        top: 0.2vw;
       right: 0.2vw;
       background-color: white;
       color: #459258;
       border-radius: 50%;
       padding: 1vw;
       font-size: 3vw;
       width: 4vw;
       height: 4vw;
       display: flex;
       justify-content: center;
       align-items: center;
     
      }
      .card-lower-part-admin-package {
       
        display: flex;
        flex-direction: column;
        border-bottom-left-radius: 1vw;
        border-bottom-right-radius: 1vw;
        height: 13vw;
        margin-top: -1vw;
        padding: 1vw;
        justify-content: center;
      }
      .card-mini-text-admin-package {
        margin-right: auto;
        margin-left: 1vw;
        font-weight: 400;
        font-size: 2vw;
      }
  }
  
.reportsColor {
  background: #f0563c;
}
.reportsLabel {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 2.5vw;
  text-align: right;
  width: 100%;
  margin-right: 4%;
  color: white;
}
.salesBG {
  object-fit: cover;
  background: url(/static/media/sales.11947f95.png);
  cursor: pointer;
}
.profitBG {
  object-fit: cover;
  background: url(/static/media/profit.25c103d7.jpg);
  cursor: pointer;
}
.customerBG {
  object-fit: cover;
  background: url(/static/media/customerReports.bb1d1b58.png);
  cursor: pointer;
}
.packageBG {
  object-fit: cover;
  background: url(/static/media/packageReports.93fba495.png);
  cursor: pointer;
}
.serviceBG {
  object-fit: cover;
  background: url(/static/media/serviceReports.a7ac9b52.png);
  cursor: pointer;
}
.employeeBG {
  object-fit: cover;
  background: url(/static/media/employeeReports.dcaffb43.png);
  cursor: pointer;
}
.inventoryBG {
  object-fit: cover;
  background: url(/static/media/inventoryReports.ae4b3eec.png);
  cursor: pointer;
}
.overlayCards {
  background-color: rgba(35, 32, 59, 0.55);
  transition: 0.15s;
}
.overlayCards:hover {
  background-color: rgba(0, 0, 0, 0.8);
}
.dsrLabel {
    cursor: pointer;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  padding: 2.5vw;
  font-size: 1.75vw;
  color: #ffffff;
  padding-top: 5vh;
  transition: 0.5s;
  height: 100%
}
.dsrLabel:hover{
    padding-top: 2vh;
    font-size: 2.25vw;

}
.invLabel {
  cursor: pointer;
font-family: Montserrat;
font-style: normal;
font-weight: 300;
padding: 2.5vw;
font-size: 1.25vw;
color: #ffffff;
padding-top: 2.5vh;
transition: 0.3s;
height: 100%
}
.invLabel:hover{
  padding-top: 2vh;
  font-size: 1.75vw;

}
.crLabel {
    cursor: pointer;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  padding: 2.5vw;
  font-size: 1.75vw;
  color: #ffffff;
  padding-top: 6.5vh;
  margin-bottom: -5vh;
  text-align: left;
  transition: 0.4s;;
}
.crLabel:hover {

    font-size: 2.25vw;;
    padding-left: 3vw;
    padding-top: 3.5vh;
}
.halkagrey{
  background-color: rgba(0, 0, 0, 0.8);

}
.rotateForward{
  transform: rotate(180deg);
}
.apnaClose{
  transform: scale(1);
  transition: 0.2s;
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
}
.apnaClose:hover{
  transform: scale(1.15)rotate(90deg);;
  -webkit-filter: grayscale(0);;
          filter: grayscale(0);
}
.apnaBack{
  transition: 0.2s;
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
}
.apnaBack:hover{
  -webkit-filter: grayscale(0);
          filter: grayscale(0);
}
.apnaForward{
  transition: 0.2s;
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
}
.apnaForward:hover{
  -webkit-filter: grayscale(0);
          filter: grayscale(0);
}


.DashBoard_container__nZU15 {
  display: flex;
  flex-direction: row;
  height: 100vh;
  flex-wrap: wrap;
  padding: 1rem;
  flex-wrap: wrap;
  /* background-color: #fff; */
}

.DashBoard_segment__2D126 {
  margin: 1rem;
  flex: 1 1;
  /* background-color: rgb(238, 238, 238); */
  background-color: #fff;
  height: 100vh;
  border-radius: 2rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  /* justify-content: space-between; */
  /* clip-path: polygon(0 6%, 100% 0, 100% 94%, 0% 100%); */
}
.DashBoard_empDrawer__2gqHe {
  background-color: #1d2d50;
  font-family: "DM Mono", monospace;
  padding: 2rem;
  flex: 3 1;
  box-shadow: 10px 10px 5px -4px rgba(0, 0, 0, 0.45);
  height: 100%;
}
.DashBoard_empCustDrawer__2f791 {
  background-color: #2c002e;
  font-family: "DM Mono", monospace;
  padding: 2rem;
  flex: 3 1;
  box-shadow: 10px 10px 5px -4px rgba(0, 0, 0, 0.45);
  height: 400%;
}
.DashBoard_empSerDrawer__FFUdM {
  background-color: #03141f;
  font-family: "DM Mono", monospace;
  padding: 2rem;
  flex: 3 1;
  box-shadow: 10px 10px 5px -4px rgba(0, 0, 0, 0.45);
  height: 400%;
}
.DashBoard_segment__2D126::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.DashBoard_segment__2D126::-webkit-scrollbar-thumb {
  background: #201c29;
  border-radius: 10px;

  box-shadow: inset 2px 2px 2px hsla(0, 0%, 100%, 0.25),
    inset -2px -2px 2px rgba(0, 0, 0, 0.25);
}

.DashBoard_segment__2D126::-webkit-scrollbar-track {
  background: linear-gradient(90deg, #201c29, #201c29 1px, #17141d 0, #17141d);
}
.DashBoard_empCard__1iM39 {
  display: flex;
  background-color: #1d2d50;
  flex-direction: column;
}
.DashBoard_textS__3opx5 {
  color: #fff;
  font-size: 1.5rem;
  align-self: center;
}
.DashBoard_textSm__OmHE6 {
  color: #fff;
  font-size: 1.5rem;
  align-self: center;
}
.DashBoard_ButtonEmp__c08xj {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  /* clip-path: polygon(1% 0, 100% 0, 100% 100%, 0 88%); */
  flex-wrap: wrap;

  /* margin: 1rem; */
  padding: 1vw;
  position: relative;
  cursor: pointer;
  transition: 200ms;
}

.DashBoard_ButtonEmp__c08xj:hover {
  transform: scale(0.9);
}
.DashBoard_ButtonEmp__c08xj:active {
  transform: scale(1);
}
.DashBoard_GridItemTop__TE-m_ {
  padding: 1vw;
  
}
.DashBoard_Icon__KtunM {
  position: absolute;
  font-size: 13rem;
  opacity: 0.3;
  overflow: hidden;
  top: -2rem;
  right: -4rem;
}
.DashBoard_Heading__3dBuj {
  font-size: 3rem;
  font-family: inherit;
  font-weight: 200;
  font: outline;
}
.DashBoard_HeadingDiv__3xeHW {
  background-color: #fe5236;
  color: #fff;
  margin: 1vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 2px;
}
.DashBoard_AddEmp__33rUW {
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  margin-top: 2vh;
  
}
.DashBoard_inputDiv__-h5Qi {
  margin: 5px;
}
.DashBoard_AddHeading__1tli9 {
  background-color: #fe5236;
  color: #fff;
  font-size: 1.4rem;
  font-weight: 200;
  display: flex;
  text-align: right;
  align-items: center;
  justify-content: flex-end;
  padding: 3px;
}
.DashBoard_NotiHeading__3lTqN {
  background-color: #1d2d50;
  color: #fff;
  font-size: 1.4rem;
  font-weight: 200;
  display: flex;
  text-align: right;
  align-items: center;
  justify-content: flex-end;
  padding: 3px;
}
.DashBoard_chartHeading__1vVBi {
  background-color: #17141d;
  color: #fff;
  font-size: 1.4rem;
  font-weight: 200;
  display: flex;
  text-align: right;
  align-items: center;
  justify-content: flex-end;
  padding: 3px;
  position: relative;
  overflow: hidden;
}
.DashBoard_insideDiv__1XJQs {
  display: flex;
  flex-wrap: wrap;
  
  margin-top: 0.5vh;
}
.DashBoard_inputIn__2vojJ {
  padding: 5px;
}
.DashBoard_boxTwo__CNctW {
  display: flex;
  flex-direction: column;
  background-color: #fff;
}
.DashBoard_notiHead__3csZh {
  background-color: #1d2d50;
  color: #fff;
  font-size: 1.3rem;
  font-weight: 100;
}

.DashBoard_notiBox__2r1li {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
}
.DashBoard_line__1q5zN {
  display: flex;
  justify-content: space-between;
  background-color: aliceblue;
  align-items: center;
  margin: 0.5rem;
  padding: 0.1rem;
}
.DashBoard_lineText__3_pgs {
  color: #1d2d50;
  font-size: 1.2rem;
  font-weight: 400;
}
.DashBoard_lineTextNum__2op4G {
  color: #1d2d50;
  font-size: 2rem;
  font-weight: 400;
}
.DashBoard_iconHead__324Pn {
  position: absolute;
  font-size: 5rem;
  opacity: 0.3;
  overflow: hidden;
  top: -2rem;
  left: 0rem;
}
.DashBoard_today__2tAA5 {
  padding: 0.2rem;
}
.DashBoard_chartBox__2T-2h {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  margin: 0.2rem;
 
}

.EmpCard_sub__2c7l3 {
  color: "red";
  font-family: "DM Mono";
  font-size: 1rem;
  display: flex;
  justify-content: "space-between";
}
.EmpCard_card__2iap4 {
  color: #000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 16px;
  border-top-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background: #fff;
  box-shadow: 0 -1rem 3rem #2a3d66;
  font-family: "DM Mono", monospace;

  transition: 0.2s;
  flex-direction: column;
  height: 12rem;
  position: relative;
  /* width: 3rem; */
  width: 20rem;
  /* min-width: 50px; */
  padding: 10px;
}
.EmpCard_card__2iap4:hover {
  transform: translateY(-1rem);
}
.EmpCard_card__2iap4:hover ~ .EmpCard_card__2iap4 {
  transform: translateY(7rem);
}
.EmpCard_card__2iap4:not(:first-child) {
  margin-top: -7rem;
}

.CustCard_sub__3Xjf_ {
  color: "red";
  font-family: "DM Mono";
  font-size: 1rem;
  display: flex;
  justify-content: "space-between";
}
.CustCard_card__2FIx4 {
  color: #000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 16px;
  border-top-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background: #fff;
  box-shadow: 0 -1rem 3rem #260028;
  font-family: "DM Mono", monospace;

  transition: 0.2s;
  flex-direction: column;
  height: 12rem;
  position: relative;
  width: 20rem;
  /* min-width: 50px; */
  padding: 10px;
}
.CustCard_card__2FIx4:hover {
  transform: translateY(-1rem);
}
.CustCard_card__2FIx4:hover ~ .CustCard_card__2FIx4 {
  transform: translateY(7rem);
}
.CustCard_card__2FIx4:not(:first-child) {
  margin-top: -7rem;
}

.ServCard_cin__2Uwgv {
  padding: 20px;
  /* border-color: #ed8337; */
  border-width: 20px;
}
.ServCard_sub__1qJ_1 {
  color: "red";
  font-family: "DM Mono";
  font-size: 1.4rem;
  display: flex;
  justify-content: "space-between";
  padding: 2px;
}
.ServCard_head__1q1oX {
  background-color: #ed8337;
  padding: 10px;
  color: aliceblue;
}
.ServCard_card__1g7bN {
  color: #000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 16px;
  border-top-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background: #fff;
  box-shadow: 0 -1rem 3rem rgb(49, 20, 51);
  font-family: "DM Mono", monospace;

  transition: 0.2s;
  flex-direction: column;
  height: 10rem;
  position: relative;
  width: 20rem;
  min-width: 50px;
  padding: 0px;
}
.ServCard_card__1g7bN:hover {
  transform: translateY(-1rem);
}
.ServCard_card__1g7bN:hover ~ .ServCard_card__1g7bN {
  transform: translateY(7rem);
}
.ServCard_card__1g7bN:not(:first-child) {
  margin-top: -7rem;
}

.NotiCard_container__Uh0nl {
  display: flex;
  flex-direction: column;
  margin: 0.5rem;
  background-color: #fcfdff;
  box-shadow: 10px 10px 29px -12px rgba(0, 0, 0, 0.75);
}
.NotiCard_header__1ATDQ {
  background-color: blueviolet;
  color: aliceblue;
  display: flex;
  justify-content: flex-end;
  padding: 0.3rem;
}
.NotiCard_headtxt__36jrp {
  font-size: 1.3rem;
  font-weight: 200;
}
.NotiCard_box__ECFv3 {
  padding: 1rem;
}
.NotiCard_det__1OOKh {
  display: flex;
  justify-content: space-between;
}

.employee-show-main-body{
    display: block;
    width: 100%;
    height: 7.5%;
}
.titleEmp{

    font-family: Montserrat;
    font-size: 20px;
    font-weight: 600;
    color: black
}
.top-tap-bar {
  display: flex;
  flex-direction: row;
  width: 50%;
  height: 5.5vh;
  background-color: white;
  margin-left: 4%;
  margin-top: 2%;
  border-radius: 0.75vw;
  justify-content: center;
  align-items: center;
  /* border-radius: 10px; */
}
.ActiveCard-customer {
  background-color: #fe5236;
  height: 5.5vh;
  border-radius: 0.75vw;
  align-items: center;
  justify-content: center;
  width: 15vw;
  display: flex;
  color: aliceblue;
  cursor: pointer;
}
.InactiveCard {
  color: grey;
  border-radius: 0.75vw;
  height: 5.5vh;
  border-radius: 0.75vw;
  align-items: center;
  justify-content: center;
  width: 15vw;
  display: flex;
  cursor: pointer;
}
.InactiveCard:hover {
  color: #fe5236;
}
.ActiveCard-customer:after {
  transition: all 1s;
}
.cust-box-customer {
  display: grid;
  height: 80%;
  width: 100%;
  margin-top: -10px;
  grid-template-rows: repeat(2, 2.5rem);
  grid-template-columns: repeat(auto-fit, 20rem);
  align-items: center;
  justify-items: center;
  grid-gap: 2rem;
  padding: 3%;
}
.button-Customer {
  /* position: absolute;
  bottom: 4%;
  right: 4%; */
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: 2.5%;
  margin-right: 4%;
}
.check-details {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  height: 10vh;
  width: 100%;
  margin-top: 9px;
  margin-left: 4%;
}
.SearchCustomer-div {
  cursor: pointer;
}

.All-edit-field-div {
  display: grid;
  height: 80%;
  width: 100%;
  margin-top: -10px;
  grid-template-rows: repeat(2, 2.5rem);
  grid-template-columns: repeat(auto-fit, 20rem);
  align-items: center;
  justify-items: center;
  grid-gap: 2rem;
  padding: 3%;
}
.Update-Customer-div {
  cursor: pointer;
  width: 300px;
  height: 50px;
  display: flex;
  align-self: center;
  margin-left: 53vw;
}
.tile1cust {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  padding-top: 6.5%;
  justify-content: center;
  font-size: 1.15vw;
}
.tile2cust {
  font-family: Montserrat;
  font-weight: 400;
  padding-top: 2.5%;
}
.printerButton{
font-size: 4vw;
padding: 1vw;
background-color: #EEEEEE;
border-radius: 150px;
cursor: pointer;
transition: 0.2s;
}
.printerButton:hover{
  background-color: gray;
  border-radius: 150px
}

@media only screen and (max-width: 600px){
  .top-tap-bar {
    display: flex;
    flex-direction: row;
    width: 90%;
    height: 5.5vh;
    background-color: white;
    margin-left: 4%;
    margin-top: 2%;
    border-radius: 0.75vw;
    justify-content: center;
    align-items: center;
    /* border-radius: 10px; */
  }
  .ActiveCard-customer {
    background-color: #fe5236;
    height: 5.5vh;
    border-radius: 0.75vw;
    align-items: center;
    justify-content: center;
    width: 35%;
    display: flex;
    color: aliceblue;
    cursor: pointer;
  }
  .InactiveCard {
    color: grey;
    border-radius: 0.75vw;
    height: 5.5vh;
    border-radius: 0.75vw;
    align-items: center;
    justify-content: center;
    width: 35%;
    display: flex;
    cursor: pointer;
  }
  .tile1cust {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    padding-top: 6.5%;
    justify-content: center;
    font-size: 2.5vw;
  }
  .tile2cust {
    font-family: Montserrat;
    font-weight: 400;
    padding-top: 2.5%;
  }

}
.top-tap-bar-invent {
    display: flex;
    flex-direction: row;
    width: 50%;
    height: 5.5vh;
    background-color: white;
    margin-left: 4%;
    margin-top: 2%;
    border-radius: 0.75vw;
    justify-content: center;
    align-items: center;
    /* border-radius: 10px; */
  }
  .ActiveCard-customer-invent {
    background-color: #fe5236;
    height: 5.5vh;
    border-radius: 0.75vw;
    align-items: center;
    justify-content: center;
    width: 15vw;
    display: flex;
    color: aliceblue;
    cursor: pointer;
  }
  .InactiveCard-invent {
    color: grey;
    border-radius: 0.75vw;
    height: 5.5vh;
    border-radius: 0.75vw;
    align-items: center;
    justify-content: center;
    width: 15vw;
    display: flex;
    cursor: pointer;
  }
  .InactiveCard-invent:hover {
    color: #fe5236;
  }
  .ActiveCard-customer-invent:after {
    transition: all 1s;
  }
  .cust-box-customer {
    display: grid;
    height: 80%;
    width: 100%;
    margin-top: -10px;
    grid-template-rows: repeat(2, 2.5rem);
    grid-template-columns: repeat(auto-fit, 20rem);
    align-items: center;
    justify-items: center;
    grid-gap: 2rem;
    padding: 3%;
  }
  .button-Customer {
    /* position: absolute;
    bottom: 4%;
    right: 4%; */
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-top: 2.5%;
    margin-right: 4%;
  }
  .check-details {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    height: 10vh;
    width: 100%;
    margin-top: 9px;
    margin-left: 4%;
  }
  .SearchCustomer-div {
    cursor: pointer;
  }

  .All-edit-field-div {
    display: grid;
    height: 80%;
    width: 100%;
    margin-top: -10px;
    grid-template-rows: repeat(2, 2.5rem);
    grid-template-columns: repeat(auto-fit, 20rem);
    align-items: center;
    justify-items: center;
    grid-gap: 2rem;
    padding: 3%;
  }
  .Update-Customer-div {
    cursor: pointer;
    width: 300px;
    height: 50px;
    display: flex;
    align-self: center;
    margin-left: 53vw;
  }
  .tile1cust-invent {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    padding-top: 6.5%;
    justify-content: center;
    font-size: 1.15vw;
  }
  .tile2cust-invent {
    font-family: Montserrat;
    font-weight: 400;
    padding-top: 2.5%;
  }
  .printerButton{
  font-size: 4vw;
  padding: 1vw;
  background-color: #EEEEEE;
  border-radius: 150px;
  cursor: pointer;
  transition: 0.2s;
  }
  .printerButton:hover{
    background-color: gray;
    border-radius: 150px
  }

  @media only screen and (max-width: 600px) {
    .top-tap-bar-invent {

      width: 90%;
      height: 5.5vh;
      background-color: white;
      margin-left: 4%;
      margin-top: 2%;
      border-radius: 0.75vw;
      justify-content: center;
      align-items: center;
      /* border-radius: 10px; */
    }
    .ActiveCard-customer-invent {
      background-color: #fe5236;
      height: 5.5vh;
      border-radius: 0.75vw;
      align-items: center;
      justify-content: center;
      width: 35%;
      display: flex;
      color: aliceblue;
      cursor: pointer;
    }
    .InactiveCard-invent {
      color: grey;
      border-radius: 0.75vw;
      height: 5.5vh;
      border-radius: 0.75vw;
      align-items: center;
      justify-content: center;
      width: 35%;
      display: flex;
      cursor: pointer;

    }
    .tile1cust-invent {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 700;
      padding-top: 6.5%;
      justify-content: center;
      font-size: 3vw;
    }
    .tile2cust-invent {
      font-family: Montserrat;
      font-weight: 400;
      padding-top: 2.5%;
    }
  }

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat";
  font-weight: 600;
} 
.serviceGridWork{
  display: grid;
  height: 80%;
  width: 100%;
  grid-template-rows: repeat(2, 2.5rem);
  grid-template-columns: repeat(auto-fit, 20rem);
  align-items: center;
  justify-items: center;
  grid-gap: 0.75rem;
  padding: 2%;
  margin-left: 1.5%;
  margin-top: -20px;
}

.ActiveCard{
  background-color:  #fe5236;
  height: 90%;
  width: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: aliceblue;
}
.ActiveCard:hover{
  
  cursor: pointer;  
}


.shadowBGAdmin {
    box-shadow: 0px 25px 65px 10px rgba(0, 0, 0, 0.15);
  }
  .inputAdmin {
    border: none;
    -webkit-appearance: none;
    padding: 5px;
    border-radius: 6px;
    font-family: "Montserrat";
  }
  
  .inputAdmin:hover {
    outline: none;
  }
  .inputAdmin:active {
    outline: none;
  }
  .inputAdmin:focus {
    outline: none;
  }
  .inputAdmin[type="text"] {
    font-size: 1rem;
    font-family: Montserrat;
    font-weight: 600;
  }
  .inputAdmin[type="number"] {
    font-size: 1rem;
    font-family: Montserrat;
    font-weight: 600;
  }
  .inputAdmin {
    font-size: 1rem;
    font-family: Montserrat;
    font-weight: 600;
  }
  
  .field-icon-admin {
    background-color: #c5c5c5;
    border-radius: 6px;
    justify-content: center;
    align-content: center;
    padding: 10px;
    font-weight: bold;
  }
  .textInputEmail{
    font-family: Montserrat;
  font-style: normal;
  font-weight: 900;
  color: #F49927;
  text-align: center;
  }
  .textInputPassword{
    font-family: Montserrat;
  font-style: normal;
  font-weight: 900;
  color: #F28642;
  text-align: center;
  }
  .buttonForward{
    cursor: pointer;
    -webkit-filter: drop-shadow(0px 0px 0px rgba(255, 255, 255, 0));
            filter: drop-shadow(0px 0px 0px rgba(255, 255, 255, 0));
    transition: 0.2s;
    transform: scale(1);
  }
  .buttonForward:hover{
    cursor: pointer;
    -webkit-filter: drop-shadow(0px 0px 15px rgba(255, 255, 255, 0.75));
            filter: drop-shadow(0px 0px 15px rgba(255, 255, 255, 0.75));
    transform: scale(1.15);
  }
  
  
.shadowBG {
  box-shadow: 0px 25px 65px 10px rgba(0, 0, 0, 0.12);
}
.logo {
  position: absolute;
  left: 30%;
  right: 58.28%;
  top: 9%;
  transition: 0.8s;
}
.logo2 {
  position: absolute;
  left: 8%;
  top: 12%;
  transition: 0.8s;
}
.logoMobile {
  /* position: absolute;
  left: 4%;
  top: 4%; */
}
.bookingBG {
  width: 6vw;
  height: 2vw;
  background: #c4c4c4;
  border-radius: 100%;
}
.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 1.5vw;
  font-family: Montserrat;
  font-weight: 700;
  color: white;
  border-radius: 100%;
  background: #e52520;
  width: 1.5vw;
  height: 1.5vw;
}
.bookingsButton {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 0.65vw;
  line-height: 100%;
  color: #233d34;
  width: 10vw;
  background: #c4c4c4;
  border-radius: 2vw;
  cursor: pointer;
  position: absolute;
  right: 25.5%;
  top: -2%;
  -webkit-filter: invert(0);
          filter: invert(0);
  transition: 0.2s;
}
.bookingsButton:hover {
  cursor: pointer;
  position: absolute;
  right: 25%;
  top: -2%;
  -webkit-filter: invert(1);
          filter: invert(1);
}
.glass {
  position: absolute;
  overflow-y: auto !important;
  top: 10%;
  right: 13%;
  width: 25vw;
  height: 0px;
  transition: 0.5s;
  background: rgba(255, 255, 255, 0.6);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 1.5vw;
}
.glassActive {
  height: 70%;
  transition: 0.5s;
}
.hrline {
  border: solid black;
  border-radius: 5px;
  border-width: 0.1px;
  opacity: 0.2;
  width: 88%;
}
.adminButton {
  cursor: pointer;
  position: absolute;
  right: 7.5%;
  top: -2%;
  -webkit-filter: invert(0);
          filter: invert(0);
  transition: 0.2s;
}
.adminButton:hover {
  cursor: pointer;
  position: absolute;
  right: 7%;
  top: -2%;
  -webkit-filter: invert(1);
          filter: invert(1);
}
.vehNoLabel {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  color: #233d34;
}
.vehNoField {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border: 2px;
  border-color: #ffb921;
  border-style: solid;
  border-radius: 8px;
  color: #233d34;
}
.orLabel {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 900;
  font-size: 3vw;
  line-height: 75px;
  text-align: center;
  color: #efefef;
}
.orLabelMobile {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 900;
  font-size: 8vw;
  line-height: 75px;
  text-align: center;
  color: #efefef;
}
.submitButton {
  cursor: pointer;
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
}
.submitButton:hover {
  -webkit-filter: grayscale(0);
          filter: grayscale(0);
}
.cancelPackDialog {
  cursor: pointer;
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
  transition: 0.3s;
}
.cancelPackDialog:hover {
  -webkit-filter: grayscale(0);
          filter: grayscale(0);
}
.haspr {
  position: absolute;
  bottom: 2%;
  cursor: pointer;
}
.button1 {
  position: absolute;
  left: 7.34%;
  top: 45%;
  background: linear-gradient(180deg, #ffb921 0%, #f07063 100%);
  cursor: pointer;
  transition: 0.4s;
}
.button1:hover {
  background: linear-gradient(180deg, #00000099 -25%, #000000 100%);
  cursor: pointer;
}
.button1Mobile {
  position: absolute;
  left: 10%;
  top: 38%;
  background: linear-gradient(180deg, #ffb921 0%, #f07063 100%);
  cursor: pointer;
}
.button2 {
  position: absolute;
  left: 28%;
  top: 45%;
  background: linear-gradient(180deg, #ffb921 0%, #f07063 100%);
  cursor: pointer;
  transition: 0.4s;
}
.button2:hover {
  background: linear-gradient(180deg, #00000099 -25%, #000000 100%);
  cursor: pointer;
}
.button2Mobile {
  position: absolute;
  left: 50%;
  top: 23%;
  background: linear-gradient(180deg, #ffb921 0%, #f07063 100%);
  cursor: pointer;
}
.button2Mobile:active {
  background: linear-gradient(180deg, #000000 0%, #000000 100%);
}
.mainSVG {
  position: absolute;
  right: 0%;
  bottom: 0%;
  border-bottom-right-radius: 25px;
  transition: 1.5s;
}
.mainSVGMobile {
  position: absolute;
  right: 0%;
  bottom: 0%;
  border-bottom-right-radius: 25px;
}
.button1Title1 {
  display: block;
  font-style: normal;
  font-family: Montserrat;
  font-weight: 300;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  padding-top: 1vw;
}
.button1Title1Mobile {
  display: inline-block;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 300;
  cursor: pointer;
  line-height: 1.35rem;
  text-align: center;
  color: #ffffff;
  top: 37%;
}
.button1Title2 {
  display: block;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 900;
  cursor: pointer;
  text-align: center;
  color: #ffffff;
}
.button1Title2Mobile {
  display: inline-block;
  font-family: Montserrat;
  font-style: normal;
  cursor: pointer;
  font-weight: 900;
  line-height: 1.35rem;
  text-align: center;
  color: #ffffff;
  top: 37%;
}
.animationLogo {
  -webkit-animation: fade 1.5s ease;
          animation: fade 1.5s ease;
}
@-webkit-keyframes fade {
  0% {
    width: 20%;
  }
}
@keyframes fade {
  0% {
    width: 20%;
  }
}
.animationLogo2 {
  -webkit-animation: fade2 1.5s ease;
          animation: fade2 1.5s ease;
}
@-webkit-keyframes fade2 {
  0% {
    width: 30%;
  }
}
@keyframes fade2 {
  0% {
    width: 30%;
  }
}
.animationButton1 {
  -webkit-animation: bounce 1.9s ease;
          animation: bounce 1.9s ease;
}
@-webkit-keyframes bounce {
  0% {
    transform: scale(0.85);
  }
}
@keyframes bounce {
  0% {
    transform: scale(0.85);
  }
}
.animationCars {
  -webkit-animation: bounce2 2s ease;
          animation: bounce2 2s ease;
}
@-webkit-keyframes bounce2 {
  0% {
    height: 85%;
  }
}
@keyframes bounce2 {
  0% {
    height: 85%;
  }
}
.adminLoginAnimation {
  -webkit-animation: bounce3 2s ease-in-out;
          animation: bounce3 2s ease-in-out;
}
@-webkit-keyframes bounce3 {
  0% {
    right: 5%;
  }
}
@keyframes bounce3 {
  0% {
    right: 5%;
  }
}
.animationHaspr {
  -webkit-animation: bounce4 1.9s ease;
          animation: bounce4 1.9s ease;
}
@-webkit-keyframes bounce4 {
  0% {
    width: 25%;
    opacity: 0;
    -webkit-filter: invert(1);
            filter: invert(1);
  }
}
@keyframes bounce4 {
  0% {
    width: 25%;
    opacity: 0;
    -webkit-filter: invert(1);
            filter: invert(1);
  }
}

.main-container-div {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-items: center;
  justify-self: center;
  justify-content: center;
  /* background: #eeeeee; */
}
.invoice-div {
  margin-top: 200vh;
  display: flex;
  bottom: 0;
}

.mainSabsePeeche {
  border-radius: 24px;
  display: flex;
  margin-left: 2.5%;
  justify-content: flex-start;
  flex-direction: row;
}
.drawerItemLeft {
  display: inline-block;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  background: white;
  margin-top: 1px;
  margin-bottom: 1px;
  margin-left: 1px;
  cursor: pointer;
  color: rgba(52, 50, 76, 0.8);
}
.drawerActiveColor {
  display: inline-block;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  background: #fe5236;
  margin-top: 1px;
  margin-bottom: 1px;
  margin-left: 1px;
  cursor: pointer;
  color: white;
}
.drawerItemLeft:hover {
  width: 100%;
  height: 100%;
  background: #fe5236;
  cursor: pointer;
  color: white;
}
.drawerActiveColor:hover {
  width: 100%;
  height: 100%;
  background: #fe5236;
  cursor: pointer;
  color: white;
}

.servicesSection {
  padding-left: 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: scroll;
}
.scrollDownPackages {
  position: absolute;
  top: 84%;
  right: -5%;
  width: 200px;
  height: 100px;
  transition: 0.2s;
  cursor: pointer;
}
.scrollDownPackages:hover {
  position: absolute;
  top: 84%;
  right: -1%;
  width: 200px;
  height: 100px;
}
.renewWhat{
  font-size: 1vw;
  text-shadow: transparent;
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
  transition: 0.1s;
}
.renewWhat:hover{
  font-size: 1.25vw;
  text-shadow: black;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}





.bookingBG {
  width: 6vw;
  height: 2vw;
  background: #c4c4c4;
  border-radius: 100%;
}
.circle2 {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 1.5vw;
  font-family: Montserrat;
  font-weight: 700;
  color: white;
  border-radius: 100%;
  background: #e52520;
  width: 1.5vw;
  height: 1.5vw;
}
.bookingsButton2 {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 0.65vw;
  line-height: 100%;
  color: #233d34;
  width: 10vw;
  background: #c4c4c4;
  border-radius: 2vw;
  cursor: pointer;
  -webkit-filter: invert(0);
          filter: invert(0);
  transition: 0.2s;
}
.bookingsButton2:hover {
  cursor: pointer;
  -webkit-filter: invert(1);
          filter: invert(1);
}
.glass2 {
  overflow-y: auto !important;
  width: 25vw;
  height: 0px;
  position: absolute;
  transition: 0.5s;
  margin-top: 1vw;
  background: rgba(255, 255, 255, 0.6);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 1.5vw;
}
.glassActive2 {
  height: 30%;
  transition: 0.5s;
}

.hrline {
  border: solid black;
  border-radius: 5px;
  border-width: 0.1px;
  opacity: 0.2;
  width: 88%;
}


.containerBG {
width: 250px;
height: 175px;
background-image: url(/static/media/kmBar.4849be2b.svg);
display: flex;
align-items: center;
justify-content: center;
}

.imageBG { 
  position: absolute;
   width: 250px;;
}

.distance { 
position: absolute;
width: 100px;
height: 75px;
left: 60px;
  top: 2%;
  font-size: 1.1rem;
  font-family: Montserrat;
  font-weight: 800;
  text-align: right;
  color: white;
  display: flex;

}
.imageBg{
  position: absolute;
  width: 200px;
  left: 60px;
  top: 2%;
}

.kilometer {
position: absolute;
  top: 2%;
  left: 150px;
  font-size: 1.1rem;
  font-family: Montserrat;
  font-weight: 400;
  text-align: right;
  color: white;
  display: flex;
  height: 100%;
}
.container-iot-tio{
  width: 92%;
  height: 92%;
  background: #EEEEEE;
  border-radius: 24px;
  flex-direction: row;
  }
  .drawer{
  
  width: 75%;
  height: 100%;
  left: 0%;
  top: 0%;
  background: #EEEEEE;
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
  }
  .tempBill{
  
  width: 25%;
  height: 100%;
  right: 0%;
  top: 0%;
  background: #DBDBDB;
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
  }
  .drawerButtons{
    
    width: 25%;
    height: 100%;
    top: 0px;
    left: 1px;
    flex-direction: column;
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;
  }
  .title1{
  position: relative;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  top: 50%;
  left: 0%;
  text-align: center;
  margin-top: 1.25px;
  margin-bottom: 1.5px;
  }
  .title2{
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  }
  .DrawerItem2{
    flex-direction: row;
    background: white;
    line-height: 19.25vh;
    margin-top: 1.5px;
    margin-bottom: 1.5px;
    cursor: pointer;
    color: rgba(52, 50, 76, 0.8);
  }
  .DrawerItem2Active{
    flex-direction: row;
    background:  #FE5236;
    line-height: 19.25vh;
    margin-top: 1.5px;
    margin-bottom: 1.5px;
    cursor: pointer;
    color:white;
  }
  .DrawerItem2:hover {
    background: #FE5236;
    cursor: pointer;
    color: white;
  }
  .DrawerItem3{
    flex-direction: row;
    background: white;
    line-height: 19.25vh;
    margin-top: 1.5px;
    margin-bottom: 1.5px;
    cursor: pointer;
    color: rgba(52, 50, 76, 0.8);
  }
  .DrawerItem3Active{
    flex-direction: row;
    background:  #FE5236;
    line-height: 19.25vh;
    margin-top: 1.5px;
    margin-bottom: 1.5px;
    cursor: pointer;
    color:white;
  }
  .DrawerItem3:hover {
    background: #FE5236;
    cursor: pointer;
    color: white;
  }
  .DrawerItem4{
    flex-direction: row;
    background: white;
    line-height: 19.25vh;
    margin-top: 1.5px;
    margin-bottom: 1.5px;
    cursor: pointer;
    color: rgba(52, 50, 76, 0.8);
  }
  .DrawerItem4Active{
    flex-direction: row;
    background:  #FE5236;
    line-height: 19.25vh;
    margin-top: 1.5px;
    margin-bottom: 1.5px;
    cursor: pointer;
    color:white;
  }
  .DrawerItem4:hover {
    background: #FE5236;
    cursor: pointer;
    color: white;
  }
  .topLeftDrawerItem{
    flex-direction: row;
    background: white;
    line-height: 19.25vh;
    margin-top: 1.5px;
    margin-bottom: 1.5px;
    border-top-left-radius: 24px;
    cursor: pointer;
    color: rgba(52, 50, 76, 0.8);
  }
  .topLeftDrawerItemActive{
    flex-direction: row;
    background:  #FE5236;
    line-height: 19.25vh;
    margin-top: 1.5px;
    margin-bottom: 1.5px;
    border-top-left-radius: 24px;
    cursor: pointer;
    color:white;
  }
  .topLeftDrawerItem:hover {
    background: #FE5236;
    cursor: pointer;
    color: white;
  }
  .bottomLeftDrawerItem{
    flex-direction: row;
    background: white;
    line-height: 19.25vh;
    margin-top: 1.5px;
    margin-bottom: 1.5px;
    border-bottom-left-radius: 24px;
    cursor: pointer;
    color: rgba(52, 50, 76, 0.8);
  }
  .bottomLeftDrawerItemActive{
    flex-direction: row;
     background: #FE5236;
    line-height: 19.25vh;
    margin-top: 1.5px;
    margin-bottom: 1.5px;
    border-bottom-left-radius: 24px;
    cursor: pointer;
      color: white;
  }
  .bottomLeftDrawerItem:hover {
    background: #FE5236;
    cursor: pointer;
    color: white;
  }
  .services{
    
  
    padding-left: 15px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    right: 0px;
    width: 74.75%;
    height: 100%;
    overflow: scroll;
  
  
  }
  h1{
    font-size: 25px;
    margin-left: 12%;
  }
.image {
  position: absolute;
  background-image: url(/static/media/TempBill.3ec6497c.svg);
  -webkit-filter: drop-shadow(0px 15px 25px rgba(0, 0, 0, 0.3));
          filter: drop-shadow(0px 15px 25px rgba(0, 0, 0, 0.3));
  top: 4%;
  margin-top: 20px;
  right: 6%;
  width:  90%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  overflow: scroll;
}
h2 {
  position: absolute;
  font-size: 20px;
  top: 60px;
  right: 60px;
  font-family: Montserrat;
  font-weight: 700;
  text-align: center;
  color: black;
  width: inherit; 
}
h1{
    font-family: Montserrat;
}
.image-contain{
  display:  flex;
  flex-direction: row;  
  justify-content: space-around;
  /* margin-top: 100px; */
  margin-left:50px ;
  

}
.text-contain{
  font-size: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  
}
.card-container {
  background-color: white;
  position: relative;
  width: 200px;
  height: 300px;
  margin: 15px ;
  border-radius: 16px;
  -webkit-filter: drop-shadow(0px 15px 30px rgba(0, 0, 0, 0.12));
          filter: drop-shadow(0px 15px 30px rgba(0, 0, 0, 0.12));

}

.mini-card {
  background-color: chocolate;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 130px;
  border-radius: 0.75em;

  background: linear-gradient(321.16deg, #ff8f80 0%, #fe5236 71.4%);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.h3 {
  position: absolute;
  color: white;
  text-align: center;
  font-family: "Montserrat";
  margin-top: 0.3em;
}
.button {
  position: absolute;
  background-color: #ffffff;
  cursor: pointer;
  margin-top: 6em;
  color: chocolate;
  border: 0;
  width: 11em;
  height: 2em;
  font-family: "Montserrat", sans-serif;
  font-size: 0.8rem;
  border-radius: 8px;
}
.button:focus {
  background-color: #34324c;
  color: white;
  border: 0rem black;
}
.bottom-mini-card {
  position: absolute;
  background-color: white;
  display: flex;
  border-radius: 0.75em;
  /* justify-content: center; */
  /* align-items: center; */
  flex-direction: column;
  width: 100%;
  margin-top: 7.5rem;
  height: 50px;
}
.bottom-mini-div {
  display: flex;
  margin-left: 1rem;
  margin-top: 0.8rem;
}
.h6 {
  margin-left: 1rem;
  margin-top: 0.3rem;
  font-weight: 700;
  font-family: Montserrat;
}

.calContainer {
  border-radius: 8px;
  background: white;
  flex-direction: column;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  text-align: center;
  padding-top: 4%;
}
.headingH1 {
  font-size: 70px;
  font-weight: 700;
  color: #34324c;
}
.headingH2 {
  font-weight: 600;
  font-family: Montserrat;
  font-weight: 600;
}
.month{
  font-weight: 700;
  font-family: Montserrat;
  color: #34324c
}
.year{
  font-weight: 700;
  font-family: Montserrat;

}
.dot1 {
  height: 15px;
  width: 15px;
  background-color: #34324c;
  border-radius: 100%;
}
.dot2 {
  height: 15px;
  width: 15px;
  background-color: #34324c;
  border-radius: 100%;
}

.donutchart-track {
  fill: transparent;
  stroke: #dadada;
  stroke-width: 26;
}
.donutchart-indicator {
  fill: transparent;
  stroke: #f0563c;
  stroke-width: 26;
  stroke-dasharray: 0 10000;
  transition: stroke-dasharray 0.3s ease;
}

.donutchart {
  margin-left: 0px;
  border-radius: 50%;
  display: block;

}

.donutchart-text {
  font-family: "Montserrat";
  fill: #f0563c;
  font-size: 20;
}
.donutchart-text-val {
  font-size: 4vw;
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
}
.donutchart-text-percent {
  font-size: 14px;
}
.donutchart-text-label {
  font-size: 1.25vw;
  fill: #000000;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}

/* .bodybody {
  padding: 40px;
  font-family: "Montserrat";
  text-align: center;
}

.inputinput {
  padding: 8px 10px;
  border-radius: 4px;
  border: 1px solid #ddd;
  width: 154px;
} */

.service-container-package {
  height: 100%;
  width: 100%;
  background-color: white;
}
.service-header-package {
  display: flex;
  align-items: center;
  text-align: center;
  font-family: "Montserrat";
  font-size: xx-large;
  width: 100%;
}
.servicesSection {
  display: inline-flexbox;
}
.service-header-package::before {
  content: "";
  flex: 3 1;
  border-bottom: 0.15rem solid #fe5236;
}
.service-header-package::after {
  content: "";
  flex: 1 1;
  border-bottom: 0.15rem solid #fe5236;
}
.service-header-package::before {
  margin-right: 0.5em;
}
.service-header-package::after {
  margin-left: 0.5em;
}
.service-box-display {
  display: grid;

  grid-template-rows: repeat(100, 1fr);
  grid-template-columns: repeat(3, 1fr);

  grid-gap: 2rem;
  overflow: scroll;
}

.drawerItemLeftPack {
  display: inline-block;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  background: white;
  margin-top: 1px;
  margin-bottom: 1px;
  margin-left: 1px;
  cursor: pointer;
  color: rgba(52, 50, 76, 0.8);
}
.drawerActiveColorPack {
  display: inline-block;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  background: #fe5236;
  margin-top: 1px;
  margin-bottom: 1px;
  margin-left: 1px;
  cursor: pointer;
  color: white;
}
.drawerItemLeftPack:hover {
  width: 100%;
  height: 100%;
  background: #fe5236;
  cursor: pointer;
  color: white;
}
.drawerActiveColorPack:hover {
  width: 100%;
  height: 100%;
  background: #fe5236;
  cursor: pointer;
  color: white;
}
.aniDraw {
  margin-left: 0;
  transition: 0.3s;
}
.anDraw:after {
  margin-left: -100%;
  transition: all 0.3s;
}

.cashAnimation {
  -webkit-filter: drop-shadow(0px 0px 0px #ffffff);
          filter: drop-shadow(0px 0px 0px #ffffff);
  transition: 0.2s;
  margin-top: 20px;
  width: 200px;
  cursor: pointer;
  margin-left: 0px;
  margin-right: 40px;
}
.cashAnimation:hover {
  -webkit-filter: drop-shadow(0px 5px 20px #c1c1c1);
          filter: drop-shadow(0px 5px 20px #c1c1c1);
  transition: 0.2s;
}

.onlineAnimation {
  -webkit-filter: drop-shadow(0px 0px 0px #ffffff);
          filter: drop-shadow(0px 0px 0px #ffffff);
  transition: 0.2s;
  margin-top: 20px;
  width: 200px;
  cursor: pointer;
  margin-left: 0px;
  margin-left: 40px;
}
.onlineAnimation:hover {
  -webkit-filter: drop-shadow(0px 5px 20px #c1c1c1);
          filter: drop-shadow(0px 5px 20px #c1c1c1);
  transition: 0.2s;
}
.buttonColorModal {
  background: #f0563c;
  background-color: #f0563c;
  color: #f0563c;
}

.container-div-main-hello {
  width: 95.5%;
  height: 100vh;
  background-color: #eeeeee;
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  margin-left: 2vw;
  margin-top: 5vw;
  border-top-left-radius: 3.5vw;
  border-top-right-radius: 3.5vw;
  padding-left: 2vw;
}
.invoice {
  width: 10vw;
}
.invoice-image {
  width: 40vw;
}
.row-1 {
  background-color: #eeeeee;
  flex: 1 1;
  height: 90%;
  margin: 3vw 1vw 1vw 3vw;
  display: flex;
  flex-direction: column;
}
.row-2-service {
  display: flex;
  flex: 1 1;
  height: 100%;
  background-color: white;
  margin: 0vw 0vw 1vw 0vw;
  flex-direction: column;
}
.Contain-left-div {
  display: flex;
  flex-direction: column;
  margin-top: -3vh;
}
.contain-span {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  font-size: 1.5vw;
}
.cash-button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 5vw;
  margin-right: 10vw;
  transform: scale(1.5);
}

h2 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 60px;
  line-height: 73px;
  letter-spacing: 0.29em;
}

.row-1-subdiv {
  display: flex;
  flex-direction: row;
  margin-top: 3rem;
  background-color: rosybrown;
}
.h4-heading {
  display: flex;
  width: 40vw;
  align-items: center;
  justify-content: space-between;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 1.5vw;
  margin-top: 4vh;
}
.service-div {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  background-color: #d2d2d2;
  align-items: center;
  justify-content: center;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 2vw
}
.amount-div {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  align-items: center;
  background-color: #eeeeee;
  justify-content: center;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 2vw;
  border-top-right-radius: 3.5vw;
}
.row-2-headerDiv-service {
  display: flex;
  flex-direction: row;
  height: 10%;
}
.row-2Contain {
  display: flex;
  flex-direction: column;
  height: 40vh;
  background-color: white;
  overflow: scroll;
  /* background-image: linear-gradient(to bottom right, white, #eeeeee); */
}
.bill-tabs-service {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 95%;
  height: 100%;
  background-color: #eeeeee;
  /* background-image: linear-gradient(to bottom right, #eeeeee, white); */
  border-radius: 10px;
  margin-top: 0.75vw;
  margin-left: 1vw;
  padding: 1vw;
}
h5 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 1.5vw;
  margin-top: 2vh;
  text-align: left;
  padding-left: 3vw;
  padding-right: 3vw;
}
.abcd {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 60px;
  line-height: 73px;
  letter-spacing: 0.29em;
}
.Total-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  margin-left: 2vw;
}
.Total-div-amt-heading {
  display: flex;
  margin-left: 1vw;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  font-size: 2vw;
  text-align: left;
  padding-left: 2vw;
  padding-right: 2vw;
}
.Total-div-amt-val {
  display: flex;
  margin-left: 1vw;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  font-size: 2vw;
  text-align: left;
  padding-left: 2vw;
  padding-right: 2vw;
}

.invoiceLogo {
  background-color: tomato;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.hr-line {
  background-color: tomato;
  border: 1px solid #fe5236;
  border-radius: 100px;
  width: 42vw;
  margin-left: 2vw;
}
.gst-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  margin-left: 2vw;
}
.gst-heading-div {
  display: flex;
  margin-left: 1vw;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  font-size: 2vw;
  text-align: left;
  padding-left: 2vw;
  padding-right: 2vw;
}
.gst-value-div {
  display: flex;
  margin-left: 1vw;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  font-size: 2vw;
  text-align: left;
  padding-left: 2vw;
  padding-right: 2vw;
}

.net-amt-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  margin-left: 2vw;
}
.net-heading-div {
  display: flex;
  margin-left: 1vw;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  font-size: 2vw;
  text-align: left;
  padding-left: 2vw;
  padding-right: 2vw;
}
.net-value-div {
  display: flex;
  margin-left: 1vw;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  font-size: 2vw;
  text-align: left;
  padding-left: 2vw;
  padding-right: 2vw;
}
.amount-div-with-delete {
  /* background-color: #f0563c; */
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.amount-div-with-delete-button {
  color: red;
  font-size: 3vw;
  cursor: pointer;
  margin-right: 10px;
}
@media only screen and (max-width: 600px) {
  .container-div-main-hello {    
    /* background-color:red; */
    display: flex;
    flex-direction: column;  
  }
 
  .row-1 {
    /* background-color:palegreen; */
    flex: 1 1;
    height: 40vh;   
  }
  .Contain-left-div{
    /* background-color: paleturquoise; */
    display: flex;
    flex: 1 1;
    height: 37vh;
    
  }
  .h4-heading {
    /* background-color: yellowgreen; */
    display: flex;
    width: 100%;
    height: 10vh;
    align-items: center;
    justify-content: space-between;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 3vw;
    margin-top: 0.5vh;
  }
  .contain-span {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    font-size: 3vw;
  }
  .cash-button {
        
    display: flex;
    justify-content: center;
    margin-top: 1vh;
    margin-left: 55vw;
    height: 5vh;
    width: 20vw;    
    transform: scale(1.5);
  }
  .row-2-service {    
    height: 30vh;
    width: 92vw;
    margin-top: 1vh;
    margin-right: 1vw;
    /* margin-left: -1vh; */
    /* background-color: yellow;     */
  }
  .row-2-headerDiv-service {
    background-color: tomato;
    /* margin-top: 0.5vh;     */
    height: 4vh;
  }
  .service-div {  
      font-size: 4vw
  }
  .amount-div {
  
    font-size: 4vw;
    border-top-right-radius: 0px;
  }
  .row-2Contain {
    display: flex;
    position: static;
    flex-direction: column;
    height: 90vh;    
    background-color: white;
    overflow: scroll;
    /* background-color: seagreen; */
    
  }
  .bill-tabs-service {  
    border-radius: 5px;    
    padding: 1vw;    
  }
  h5 {   
    font-size: 3vw;    
  }
  .hr-line {  
    width: 80vw;   
  }
  .Total-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    margin-left: 2vw;
    /* background-color: yellowgreen; */
  }
  .Total-div-amt-heading {
   
    font-size: 3vw;
   
    /* background-color: red; */
  }
  .Total-div-amt-val {
    /* background-color: royalblue; */
    
    font-size: 3vw;
    
  }
  .gst-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    margin-left: 2vw;
    /* background-color: royalblue; */
  }
  .gst-heading-div {
   
    font-size: 3vw;
   
  }
  .gst-value-div {
    
    font-size: 3vw;
   
  }
  .net-amt-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    margin-left: 2vw;
    /* background-color: seagreen; */
  }
  .net-heading-div {
   
    font-size: 4vw;
   
    
  }
  .net-value-div {
   
    font-size: 4vw;
    
  }
}
html {
  font-size: 17px;
}
@media (max-width: 900px) {
  html {
    font-size: 15px;
  }
}
@media (max-width: 600px) {
  html {
    font-size: 13px;
    
  }
  .card-for-package-container {
    min-width:  100px;
       font-size: 1vw;
  }
}
.card-for-package-container {
  width: 11.2vw;
  display: flex;
  flex-direction: column;
  font-size: 1vw;
}
.mother-card {
  width: 12vw;
  border-radius: 1vw;
  margin-left: 1vw;
  margin-right: 0.5vw;
  margin-top: 2vw;
}
.card-upper-part {
  background: linear-gradient(321.16deg, #ff8f80 0%, #fe5236 71.4%);
  border-radius: 1vw;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  white-space: pre-line;
  height: 9vw;
  color: white;
  font-size: 1.1vw;
  text-align: center;
  position: relative;
}

.card-upper-part-green {
  position: relative;
  background: linear-gradient(321.67deg, #77dd77 0%, #1b533e 100%);
  /* border-top-left-radius: 1rem;
  border-top-right-radius: 1rem; */
  border-radius: 1vw;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  white-space: pre-line;
  /* height: 10rem; */
  height: 9vw;
  color: white;
  font-size: 1.1vw;
  text-align: center;
}
.card-top-button {
  cursor: pointer;
  border-radius: 0.5vw;
}
.card-top-button text {
  background-color: #fff;
  color: #000;
  mix-blend-mode: screen;
  font-weight: 900;
  padding-left: 2vw;
  padding-right: 2vw;
  padding-top: 0.2vw;
  padding-bottom: 0.2vw;
  border-radius: 0.4vw;
  font-size: 0.9vw;
  margin: 0;
  transition-duration: 0.1s;
}
.card-top-button text:hover {
  background-color: #333333;
  color: #fff;
  mix-blend-mode: multiply;

  font-weight: 1000;
  padding-left: 2vw;
  padding-right: 2vw;
  padding-top: 0.2vw;
  padding-bottom: 0.2vw;
  border-radius: 0.5vw;
  font-size: 1vw;
  margin: 0;
  opacity: 0.9;
  box-shadow: 10px 10px 25px -10px rgba(0, 0, 0, 1);
}
.card-top-button:hover {
  transform: scale(1.1);
}
.card-lower-part {
  background: white;
  display: flex;
  flex-direction: column;
  border-bottom-left-radius: 1vw;
  border-bottom-right-radius: 1vw;
  height: 10vw;
  margin-top: -1vw;
  padding: 1vw;
  justify-content: center;
}
.card-mini-div {
  display: flex;
  align-items: center;
  padding: 0.4vw;
}
.card-mini-text {
  margin-right: auto;
  margin-left: 1vw;
  font-weight: 400;
  font-size: 0.75vw;
}
.card-top-text {
  width: min-intrinsic; /* old Chrome, Safari */
  width: -webkit-min-content; /* less old Chrome, Safari */
  width: -moz-min-content; /* current Firefox */
  width: min-content; /* current Chrome, Safari; not IE or Edge */
}

.card-top-button-selected {
  cursor: pointer;
  border-radius: 0.5vw;
}
.card-top-button-selected text {
  background-color: #333333;
  color: #fff;
  font-weight: bolder;
  padding-left: 2vw;
  padding-right: 2vw;
  padding-top: 0.1vw;
  padding-bottom: 0.1vw;
  border-radius: 0.5vw;
  font-size: 1vw;
  margin: 0;
  opacity: 0.9;
  box-shadow: 10px 10px 25px -10px rgba(0, 0, 0, 1);
}
.card-top-button-selected text:hover {
  background-color: #fff;
  color: #000;
  mix-blend-mode: screen;
  font-weight: bolder;
  padding-left: 2vw;
  padding-right: 2vw;
  padding-top: 0.2vw;
  padding-bottom: 0.2vw;
  border-radius: 0.5vw;
  font-size: 1vw;
  margin: 0;
  transition-duration: 0.1s;
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat";
  font-weight: 600;
}
.cust-box {
  display: grid;
  height: 75vh;
  margin-top: 13%;
  width: 100%;
  background-color: #eeeeee;
  grid-template-rows: repeat(4, 2.5rem);
  grid-template-columns: repeat(auto-fit, 20rem);
  align-items: center;
  justify-items: center;
  flex: 1 1;
}
.veh-box {
  display: grid;
  height: 75vh;
  margin-top: 13%;
  width: 100%;
  background-color: #eeeeee;
  grid-template-rows: repeat(4, 2.5rem);
  grid-template-columns: repeat(auto-fit, 20rem);
  align-items: center;
  justify-items: center;
}
.scrollDown {
  position: absolute;
  top: 84%;
  right: -5%;
  width: 200px;
  height: 100px;
  transition: 0.2s;
  cursor: pointer;
}
.scrollDown:hover {
  position: absolute;
  top: 84%;
  right: -1%;
  width: 200px;
  height: 100px;
}
.service-header {
  display: flex;
  align-items: center;
  text-align: center;
  font-family: "Montserrat";
  font-size: xx-large;
  width: 100%;
  padding-top: 7vw;
}
.service-header::before {
  content: "";
  flex: 3 1;
  border-bottom: 0.15rem solid #fe5236;
}
.service-header::after {
  content: "";
  flex: 1 1;
  border-bottom: 0.15rem solid #fe5236;
}
.service-header::before {
  margin-right: 0.5em;
}
.service-header::after {
  margin-left: 0.5em;
}

.newInvoice-mainContainer{
    display: flex;
    flex-direction: column;
    flex: 1 1;
    height: 100vw;
   
}
.newInvoice-section1{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 2vw;
}
.newInvoice-section1-logo{
    width: 20vw;
    max-width: 20vw;
    height: auto;
    max-height: 100%;
}
.newInvoice-section1-heading{
    display: flex;
    flex-direction: column;
    /* background-color: blue; */
   

}
.newInvoice-section1-heading-spanInnvo{
font-size: 2.5vw;
font-style: normal;
font-weight: 600;
letter-spacing: 0.5vw;
text-align: right;

}
.newInvoice-section1-heading-spanMohan{
    
font-size: 1.69vw;
font-style: normal;
font-weight: 300;
text-align: right;

}
.newInvoice-section1-heading-spanNumber{
font-size: 1.69vw;
font-style: normal;
font-weight: 300;
text-align: right;


}
.newInvoice-section2{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 2vw;
    margin-left: 3vw;
    margin-right: 3vw;
}
.newInvoice-section2-billed{
    display: flex;
    flex-direction: column;
}
.newInvoice-section2-billedSpan{
    
    font-style: normal;
    font-weight: 500;
    font-size: 2vw;
    text-transform: uppercase;    
    color: #C90000;
    margin-bottom: 1.5vw;
}
.newInvoice-section2-detailsSpan{
font-style: normal;
font-weight: 500;
font-size: 1.35vw;
color: #000000;
}
.barcode {
    display: flex;
    margin-top: -10%;
    max-width: 50%;
    /* transform: scale(0.5); */
}
.newInvoice-section2-packageDetails{
    display: flex;
    flex-direction: column;
}
.newInvoice-section2-invoiceDetails{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
/* .newInvoice-barcode{
    width: 5vw;
    height: 5vw;
} */
.newInvoice-section2-total{
    display: flex;
    flex-direction: column;
}
.section2-billedSpan-total{
    
font-style: normal;
font-weight: 500;
font-size: 3vw;
color: #000000;
}
.kariya-line{
    margin-top: 0px;   
    margin-bottom: 0px;
    border: 1px solid #000000;
    margin-left: 3vw;
    margin-right: 3vw;
}
.newInvoice-section3{
    display: flex;
    flex-direction: column;
}
.newInvoice-section3-heading{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 2vw;
    margin-left: 3vw;
    margin-right: 3vw;
}
.newInvoice-section3-headingContain{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 3vw;
    margin-right: 3vw;
}
.newInvoice-section2-billedSpanContain{
font-style: normal;
font-weight: 500;
font-size: 1.5vw;    
color: #000000;
}
.newInvoice-section3-GstDetails{
    display: flex;
    flex-direction: row;
    justify-content: space-between;    
    padding: 3vw
}
.section3-GstDetails-1stHalf{
    display: flex;
    flex-direction: column;
    flex: 1 1;
    /* background-color: red; */
}

.GstDetails-1stHalf-details{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.section3-GstDetails-2ndHalf{
    display: flex;
    flex-direction: column;
    flex: 1 1;
    align-items: flex-end;
    justify-content: center;
}
.newInvoice-section3-subTotal{
font-style: normal;
font-weight: 300;
font-size: 1.5vw;
line-height: 3vw;
color: #000000;
}
.newInvoice-section3-subTotal-div{
    display: flex;
    width: 70%;
    justify-content: space-between;
}
.newInvoice-section4{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 2vw;
    margin-left: 3vw;
    margin-right: 3vw;
}
.term-n-condtion-heading{
    
font-style: normal;
font-weight: 300;
font-size: 1.5vw;
color: #C80000;
}
.term-n-condtion-kuch-lekha{
    font-size: 1vw;
    font-style: normal;
    font-weight: 300;
    text-align: left;
    
}
.NewInvoice-section5{
    display: flex;    
    flex-direction: column;
    align-items: flex-end;    
    margin-left: 3vw;
    margin-right: 3vw;
}
.NewInvoice-section5-icons{
    display: flex;
    flex-direction: row;
}
.newInvoice-section5-logo{
    width: 5vw;
    height: 5vw;
    margin: 1vw;
}
.desgin{
font-family: Poiret One;
font-style: normal;
font-weight: normal;
font-size: 2vw;
line-height: 2.5vw;
text-align: center;
}
.haspr-span{
    font-style: normal;
    font-weight: normal;
    font-size: 1vw;
    line-height: 2.5vw;
    text-align: center;
    letter-spacing: 1vw;    
    color: #000000;
}



* {
    padding: 0;
    margin: 0;
  }
