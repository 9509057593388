* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat";
  font-weight: 600;
}
.cust-box {
  display: grid;
  height: 75vh;
  margin-top: 13%;
  width: 100%;
  background-color: #eeeeee;
  grid-template-rows: repeat(4, 2.5rem);
  grid-template-columns: repeat(auto-fit, 20rem);
  align-items: center;
  justify-items: center;
  flex: 1;
}
.veh-box {
  display: grid;
  height: 75vh;
  margin-top: 13%;
  width: 100%;
  background-color: #eeeeee;
  grid-template-rows: repeat(4, 2.5rem);
  grid-template-columns: repeat(auto-fit, 20rem);
  align-items: center;
  justify-items: center;
}
.scrollDown {
  position: absolute;
  top: 84%;
  right: -5%;
  width: 200px;
  height: 100px;
  transition: 0.2s;
  cursor: pointer;
}
.scrollDown:hover {
  position: absolute;
  top: 84%;
  right: -1%;
  width: 200px;
  height: 100px;
}
.service-header {
  display: flex;
  align-items: center;
  text-align: center;
  font-family: "Montserrat";
  font-size: xx-large;
  width: 100%;
  padding-top: 7vw;
}
.service-header::before {
  content: "";
  flex: 3;
  border-bottom: 0.15rem solid #fe5236;
}
.service-header::after {
  content: "";
  flex: 1;
  border-bottom: 0.15rem solid #fe5236;
}
.service-header::before {
  margin-right: 0.5em;
}
.service-header::after {
  margin-left: 0.5em;
}
