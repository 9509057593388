.button-container {
  position: relative;
  display: flex;
  border-radius: 1vw;
  transition: 0.4s;  
  align-items: center;
  background: white;
  cursor: pointer;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.085));
}
.button-container:active {
  background: #fe5236;
}
.button-container:active > .titleForSubmit {
  color: white;
}
.button-container:active > .chotaArrow {
  fill: white;
}
.button-container:hover > .button-icon {
  width: 100%;
  justify-content: left;
  align-items: left;
  align-items: left;
  text-align: left;
  padding-left: 1vw;
  opacity: 0;
}
.button-container:hover > .titleForSubmit {
  margin-left: -2vw;
}
.button-container:hover > .chotaArrow {
  right: 2vw;
  filter: invert(0);
}
.button-icon {
  position: absolute;
  transition: 0.4s;
  display: flex;
  left: 0;
  padding-left: 0px;
  background-color: #fe5236;
  border-radius: 1vw;
  height: 100%;
  width: 20%;
  justify-content: center;
  align-items: center;
  align-items: center;
  text-align: center;
  font-weight: bold;
  opacity: 1;
}
.titleForSubmit {
  position: absolute;
  width: 100%;
  transition: 0.4s;
  display: flex;
  justify-content: center;
  font-family: "Montserrat";
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  text-align: center;
  color: #34324c;
}
.chotaArrow {
  transition: 0.4s;
  position: absolute;
  right: 1.5vw;
  height: 25%;
  filter: invert(1);
}
