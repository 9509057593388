.package-screen-container {
  display: grid;
  height: 80vh;
  width: 100%;
  grid-template-rows: repeat(5, 1fr);
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10mm;
  overflow: scroll;
  align-self: center;
}
.button-Package {
  cursor: pointer;
  padding-left: 30px;
  position: absolute;
  bottom: 8%;
  right: 8%;
}
.Top-tap-package {
  display: flex;
  flex-direction: row;
  width: 80vw;
  height: 7vh;
  justify-content: flex-start;
  align-items: center;
}
.InactiveCardPack {
  height: 5.5vh;
  align-items: center;
  justify-content: center;
  width: 10vw;
  display: flex;
  color: gray;
  border-radius: 1vw;
  background-color: white;
}
.InactiveCardPack:hover {
  color: #fe5236;
}
.ActiveCard-package {
  background-color: #fe5236;
  height: 5.5vh;
  align-items: center;
  justify-content: center;
  width: 10vw;
  display: flex;
  color: aliceblue;
  border-radius: 1vw;
  cursor: pointer;
}
.ActiveCard-package:after {
  transition: all 2s;
}
.tile1Pack {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  margin-top: 10%;
  justify-content: center;
  font-size: 0.75vw;
}
.tile2Pack {
  font-family: Montserrat;

  font-weight: 400;
}
.drawerBackThing {
  border-radius: 1vw;
  display: flex;
  flex-direction: row;
  background-color: white;
  overflow: scroll;
}
.view-package-card {
  display: flex;
  flex-wrap: wrap;
  /* flex-flow: column wrap; */
  width: 75vw;
  height: 70vh;
  margin-top: 5vh;
  margin-left: 5vh;

  overflow: scroll;
}
.select-package-drop {
  display: flex;
  flex-wrap: wrap;
  width: 70vw;
  margin-left: 3.5vw;
}
.label-price {
  background-color: white;
  width: 15vw;
  margin-left: 1vw;
  padding-left: 1vw;
  display: flex;
  align-items: center;
}
.drop-div{
  /* background-color: chocolate; */
}
@media only screen and (max-width: 1024px) {
  .label-price {
    font-size: 10px;
    width: 13vw;
    padding-left: 0.5vw;
  }
  .select-package-drop {
    width: 100vw;
  }
}

@media only screen and (max-width: 600px) {
  .Top-tap-package {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 7vh;
    margin-left: 6%;
    justify-content: flex-start;
    align-items: center;
    border-radius: 1vw;
  }
  .drawerBackThing {
    border-radius: 1vw;
    width: 90%;
    background-color: white;
    overflow: scroll;
  }
  .ActiveCard-package {   
    height: 6vh;
    width: 60vw;
    display: flex;
    color: aliceblue;
    border-radius: 1vw;
    cursor: pointer;
    padding-left: 3px;
   
  }
  .tile1Pack {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    margin-top: 10%;
    justify-content: center;
    font-size: 12px;
  }
  .tile2Pack {
    font-family: Montserrat;  
    font-weight: 400;
  }
  .InactiveCardPack {    
    height: 6vh;   
    width: 60vw;    
    background-color: white;    
    
  }
  .drop-div{
    
    width: 90vw;
  }
  .label-price {
    background-color: white;
    margin-top: 1vh;
    height: 5vh;
    width: 30vw;
    margin-left: 1vw;
    padding-left: 1vw;
    display: flex;
    align-items: center;
    font-size: small;
  }
  .view-package-card {
    display: flex;
    
    /* flex-flow: column wrap; */
    width: 75vw;
    height: 70vh;
    margin-top: 5vh;
    margin-left: 5vh;
    
    overflow: scroll;
  }
  

}
