html {
  font-size: 17px;
}
@media (max-width: 900px) {
  html {
    font-size: 15px;
  }
}
@media (max-width: 600px) {
  html {
    font-size: 13px;
    
  }
  .card-for-package-container {
    min-width:  100px;
       font-size: 1vw;
  }
}
.card-for-package-container {
  width: 11.2vw;
  display: flex;
  flex-direction: column;
  font-size: 1vw;
}
.mother-card {
  width: 12vw;
  border-radius: 1vw;
  margin-left: 1vw;
  margin-right: 0.5vw;
  margin-top: 2vw;
}
.card-upper-part {
  background: linear-gradient(321.16deg, #ff8f80 0%, #fe5236 71.4%);
  border-radius: 1vw;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  white-space: pre-line;
  height: 9vw;
  color: white;
  font-size: 1.1vw;
  text-align: center;
  position: relative;
}

.card-upper-part-green {
  position: relative;
  background: linear-gradient(321.67deg, #77dd77 0%, #1b533e 100%);
  /* border-top-left-radius: 1rem;
  border-top-right-radius: 1rem; */
  border-radius: 1vw;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  white-space: pre-line;
  /* height: 10rem; */
  height: 9vw;
  color: white;
  font-size: 1.1vw;
  text-align: center;
}
.card-top-button {
  cursor: pointer;
  border-radius: 0.5vw;
}
.card-top-button text {
  background-color: #fff;
  color: #000;
  mix-blend-mode: screen;
  font-weight: 900;
  padding-left: 2vw;
  padding-right: 2vw;
  padding-top: 0.2vw;
  padding-bottom: 0.2vw;
  border-radius: 0.4vw;
  font-size: 0.9vw;
  margin: 0;
  transition-duration: 0.1s;
}
.card-top-button text:hover {
  background-color: #333333;
  color: #fff;
  mix-blend-mode: multiply;

  font-weight: 1000;
  padding-left: 2vw;
  padding-right: 2vw;
  padding-top: 0.2vw;
  padding-bottom: 0.2vw;
  border-radius: 0.5vw;
  font-size: 1vw;
  margin: 0;
  opacity: 0.9;
  box-shadow: 10px 10px 25px -10px rgba(0, 0, 0, 1);
}
.card-top-button:hover {
  transform: scale(1.1);
}
.card-lower-part {
  background: white;
  display: flex;
  flex-direction: column;
  border-bottom-left-radius: 1vw;
  border-bottom-right-radius: 1vw;
  height: 10vw;
  margin-top: -1vw;
  padding: 1vw;
  justify-content: center;
}
.card-mini-div {
  display: flex;
  align-items: center;
  padding: 0.4vw;
}
.card-mini-text {
  margin-right: auto;
  margin-left: 1vw;
  font-weight: 400;
  font-size: 0.75vw;
}
.card-top-text {
  width: min-intrinsic; /* old Chrome, Safari */
  width: -webkit-min-content; /* less old Chrome, Safari */
  width: -moz-min-content; /* current Firefox */
  width: min-content; /* current Chrome, Safari; not IE or Edge */
}

.card-top-button-selected {
  cursor: pointer;
  border-radius: 0.5vw;
}
.card-top-button-selected text {
  background-color: #333333;
  color: #fff;
  font-weight: bolder;
  padding-left: 2vw;
  padding-right: 2vw;
  padding-top: 0.1vw;
  padding-bottom: 0.1vw;
  border-radius: 0.5vw;
  font-size: 1vw;
  margin: 0;
  opacity: 0.9;
  box-shadow: 10px 10px 25px -10px rgba(0, 0, 0, 1);
}
.card-top-button-selected text:hover {
  background-color: #fff;
  color: #000;
  mix-blend-mode: screen;
  font-weight: bolder;
  padding-left: 2vw;
  padding-right: 2vw;
  padding-top: 0.2vw;
  padding-bottom: 0.2vw;
  border-radius: 0.5vw;
  font-size: 1vw;
  margin: 0;
  transition-duration: 0.1s;
}
